import React, { Component } from "react";
import {
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
  Button
} from "reactstrap";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "react-toggle/style.css";
import NumberFormat from "react-number-format";

class UserForm extends Component {
  static defaultProps = {
    user: {}
  };

  state = {
    login: this.props.user.user,
    active: this.props.user.active,
    role: this.props.user.role
  };

  handleChange = event => {
    const target = event.target.id;
    const value = event.target.value;
    this.setState({ [target]: value });
  };

  render() {
    return (
      <Container>
        <Form>
          <Row form>
            <Col>
              <FormGroup>
                <Input
                  type="text"
                  name="login"
                  id="login"
                  value={this.state.login}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Input
                  type="select"
                  name="active"
                  id="active"
                  value={this.state.active}
                  onChange={this.handleChange}
                >
                  <option>1</option>
                  <option>0</option>
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Input
                  type="select"
                  name="role"
                  id="role"
                  value={this.state.role}
                  onChange={this.handleChange}
                >
                  <option>superuser</option>
                  <option>officeuser</option>
                  <option>voluser</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              {" "}
              <Button
                onClick={e => {
                  e.preventDefault();
                  this.props.saveUser(
                    this.state.login,
                    this.state.active,
                    this.state.role
                  );
                }}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    );
  }
}

export default UserForm;

const CustomInput = ({ date, ...others }) => (
  <Input {...others} value={moment(date).format("MM/DD/YYYY")} />
);
