const options = [
  { value: "admin", label: "admin" },
  { value: "karlene.swaynie", label: "karlene.swaynie" },
  { value: "lisa.castillo", label: "lisa.castillo" },
  { value: "brandi.reilly", label: "brandi.reilly" },
  { value: "mary.shelton", label: "mary.shelton" },
  { value: "beverly.wilborn", label: "beverly.wilborn" },
  { value: "jessica.munoz-lopez", label: "jessica.munoz-lopez" },
  { value: "karina.rendon.ortiz", label: "karina.rendon.ortiz" },
  { value: "becky.vogel", label: "becky.vogel" },
  { value: "amy.willingham", label: "amy.willingham" },
  { value: "jamie.sanders", label: "jamie.sanders" },
  { value: "sandi.preston", label: "sandi.preston" },
  { value: "saranne.gans", label: "saranne.gans" },
  { value: "vicki.gammill", label: "vicki.gammill" },
  { value: "ann.rundeen", label: "ann.rundeen" },
  { value: "annette.cheng", label: "annette.cheng" },
  { value: "mia.rabe", label: "mia.rabe" },
  { value: "jackie.billingsley", label: "jackie.billingsley" },
  { value: "irma.gianuzzi", label: "irma.gianuzzi" },
  { value: "harriet.black", label: "harriet.black" },
  { value: "vianey.mansoori", label: "vianey.mansoori" },
  { value: "jellica.plascencia", label: "jellica.plascencia" },
  { value: "nancy.kuhl", label: "nancy.kuhl" },
  { value: "frahana.ali", label: "frahana.ali" },
  { value: "vilma.soto", label: "vilma.soto" },
  { value: "stephanie.conner", label: "stephanie.conner" },
  { value: "mai.pham-hedrich", label: "mai.pham-hedrich" },
  { value: "betsy.lowe", label: "betsy.lowe" },
  { value: "brenda.harwick", label: "brenda.harwick" },
  { value: "lizette.sosa", label: "lizette.sosa" },
  { value: "katrice.rand", label: "katrice.rand" },
  { value: "ann.nicholson", label: "ann.nicholson" },
  { value: "naomi.rodriguez", label: "naomi.rodriguez" },
  { value: "paulette.austin", label: "paulette.austin" },
  { value: "shay.mooney", label: "shay.mooney" },
  { value: "patricia.diaz", label: "patricia.diaz" },
  { value: "intern", label: "intern" },
  { value: "intern2", label: "intern2" },
];

export default options;
