import React, { Component } from "react";
import {
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
  Button,
  Label,
  FormFeedback
} from "reactstrap";
import moment from "moment";
import "react-toggle/style.css";
import NumberFormat from "react-number-format";

class PasswordForm extends Component {
  static defaultProps = {
    household: {}
  };

  state = {
    oldPassword: null,
    newPasswordFirst: "",
    newPasswordSecond: "",
    validatePassword: true
  };

  handleChange = event => {
    const target = event.target.id;
    const value = event.target.value;
    this.setState({ [target]: value });
    this.setState({
      validatePassword:
        target === "newPasswordSecond"
          ? this.state.newPasswordFirst === event.target.value &&
            this.state.newPasswordFirst !== ""
          : this.state.validatePassword
    });
  };

  render() {
    return (
      <Container>
        <Form>
          <Row form>
            <Col>
              <FormGroup>
                <Label for="oldPassword">Old Password</Label>
                <Input
                  type="text"
                  name="oldPassword"
                  id="oldPassword"
                  value={this.state.oldPassword}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="newPasswordFirst">Type New Password</Label>
                <Input
                  type="text"
                  name="newPasswordFirst"
                  id="newPasswordFirst"
                  value={this.state.newPasswordFirst}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="newPasswordSecond">
                  Please Retype New Password
                </Label>
                <Input
                  type="text"
                  name="newPasswordSecond"
                  id="newPasswordSecond"
                  value={this.state.newPasswordSecond}
                  onChange={this.handleChange}
                  invalid={!this.state.validatePassword}
                />
                <FormFeedback invalid>
                  Does not match! Please retype password!
                </FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            {this.state.validatePassword && (
              <Col>
                {" "}
                <Button
                  onClick={e => {
                    e.preventDefault();
                    this.props.savePassword(
                      this.state.oldPassword,
                      this.state.newPasswordSecond
                    );
                  }}
                >
                  Save
                </Button>
              </Col>
            )}
          </Row>
        </Form>
      </Container>
    );
  }
}

export default PasswordForm;
