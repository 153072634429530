const options = [
  { link: "Food", label: "Food", value: "Food" },
  { link: "Food", label: "Senior", value: "Senior" },
  { link: "Food", label: "Veteran", value: "Veteran" },
  { link: "Food", label: "Project Pass", value: "Project Pass" },
  { link: "Pantry/Other", label: "Adult Diapers", value: "Adult Diapers" },
  { link: "Pantry/Other", label: "Toiletries", value: "Toiletries" },
  { link: "Pantry/Other", label: "Diapers", value: "Diapers" },
  { link: "Pantry/Other", label: "Formula", value: "Formula" },
  { link: "Pantry/Other", label: "Fan", value: "Fan" },
  { link: "Pantry/Other", label: "Wipes", value: "Wipes" },
  { link: "Financial", label: "Bus", value: "Bus" },
  { link: "Financial", label: "Electric", value: "Electric" },
  { link: "Financial", label: "Gas/Heat", value: "Gas/Heat" },
  { link: "Financial", label: "Gift Card", value: "Gift Card" },
  {
    link: "Financial",
    label: "Holiday Assistance",
    value: "Holiday Assistance",
  },
  { link: "Financial", label: "Rent/CDBG", value: "Rent/CDBG" },
  {
    link: "Financial",
    label: "Rent/Govt Employee",
    value: "Rent/Govt Employee",
  },
  { link: "Financial", label: "Rent/Mrtg", value: "Rent/Mrtg" },
  { link: "Financial", label: "RX", value: "RX" },
  { link: "Financial", label: "Water", value: "Water" },
  { link: "Financial", label: "$$ Mgmt", value: "$$ Mgmt" },
  { link: "Financial", label: "Education", value: "Education" },
  { link: "Financial", label: "Rent/CDBG COVID", value: "Rent/CDBG COVID" },
  { link: "Financial", label: "Rent/EFSP", value: "Rent/EFSP" },
  { link: "Financial", label: "Rent/ARPAR", value: "Rent/ARPAR" },
  { link: "Information and Referral", label: "Utilities", value: "Utilities" },
  { link: "Information and Referral", label: "Food", value: "Food" },
  {
    link: "Information and Referral",
    label: "Invest In Yourself",
    value: "Invest In Yourself",
  },
  { link: "Information and Referral", label: "Rent/Mrtg", value: "Rent/Mrtg" },
  { link: "Information and Referral", label: "Clothing", value: "Clothing" },
  { link: "Information and Referral", label: "Employed", value: "Employed" },
  {
    link: "Information and Referral",
    label: "Unemployed",
    value: "Unemployed",
  },
  { link: "Information and Referral", label: "Gas/Heat", value: "Gas/Heat" },
  { link: "Information and Referral", label: "Job Fair", value: "Job Fair" },
  { link: "Information and Referral", label: "Clothing", value: "Clothing" },

  { link: "Information and Referral", label: "ES", value: "ES" },
  {
    link: "Information and Referral",
    label: "Job Search Seminar",
    value: "Job Search Seminar",
  },

  { link: "Information and Referral", label: "IISD ESL", value: "IISD ESL" },
  { link: "Information and Referral", label: "IISD GED", value: "IISD GED" },
  { link: "Information and Referral", label: "DC ESL", value: "DC ESL" },
  { link: "Information and Referral", label: "DC GED", value: "DC GED" },
  { link: "Information and Referral", label: "DC ET", value: "DC ET" },
  { link: "Information and Referral", label: "Homeless", value: "Homeless" },
  {
    link: "Information and Referral",
    label: "Household Items",
    value: "Household Items",
  },
  { link: "Information and Referral", label: "Housing", value: "Housing" },
  { link: "Information and Referral", label: "Misc.", value: "Misc." },
  {
    link: "Information and Referral",
    label: "Other Rent",
    value: "Other Rent",
  },
  { link: "Information and Referral", label: "RX", value: "RX" },
  { link: "Information and Referral", label: "Spanish", value: "Spanish" },
  {
    link: "Information and Referral",
    label: "Transportation",
    value: "Transportation",
  },
  { link: "Information and Referral", label: "Gov Rent", value: "Gov Rent" },
  {
    link: "Information and Referral",
    label: "Gov Utilities",
    value: "Gov Utilities",
  },

  {
    link: "Information and Referral",
    label: "Salvation Army",
    value: "Salvation Army",
  },
  { link: "Information and Referral", label: "ESL", value: "ESL" },
  {
    link: "Information and Referral",
    label: "Knights of Columbus",
    value: "Knights of Columbus",
  },
  { link: "Information and Referral", label: "SNAP", value: "SNAP" },
  { link: "Information and Referral", label: "Senior", value: "Senior" },
  {
    link: "Information and Referral",
    label: "Thanksgiving",
    value: "Thanksgiving",
  },
  { link: "Information and Referral", label: "Water", value: "Water" },
  { link: "Information and Referral", label: "Hotel", value: "Hotel" },
  { link: "Information and Referral", label: "Dayspring", value: "Dayspring" },
  {
    link: "Invest In Yourself",
    label: "Benefits Screening = No",
    value: "Benefits Screening = No",
  },
  {
    link: "Invest In Yourself",
    label: "Benefits Screening = Yes",
    value: "Benefits Screening = Yes",
  },
  {
    link: "Invest In Yourself",
    label: "CK Credit = No",
    value: "CK Credit = No",
  },
  {
    link: "Invest In Yourself",
    label: "CK Credit = Yes",
    value: "CK Credit = Yes",
  },
  { link: "Invest In Yourself", label: "Coaching", value: "Coaching" },
  { link: "Invest In Yourself", label: "Computer Lab", value: "Computer Lab" },
  {
    link: "Invest In Yourself",
    label: "Connections NLC",
    value: "Connections NLC",
  },
  {
    link: "Invest In Yourself",
    label: "Credit Score > 500",
    value: "Credit Score > 500",
  },
  {
    link: "Invest In Yourself",
    label: "Credit Score < 500",
    value: "Credit Score < 500",
  },
  {
    link: "Invest In Yourself",
    label: "Credit Score > 600",
    value: "Credit Score > 600",
  },
  {
    link: "Invest In Yourself",
    label: "Credit Score < 600",
    value: "Credit Score < 600",
  },
  { link: "Invest In Yourself", label: "$$ Mgmt", value: "$$ Mgmt" },
  { link: "Invest In Yourself", label: "Employed?", value: "Employed?" },
  {
    link: "Invest In Yourself",
    label: "Financial Capability Score",
    value: "Financial Capability Score",
  },
  { link: "Invest In Yourself", label: "GED", value: "GED" },
  {
    link: "Invest In Yourself",
    label: "IIY Orientation",
    value: "IIY Orientation",
  },
  {
    link: "Invest In Yourself",
    label: "Improved Credit Score = Yes",
    value: "Improved Credit Score = Yes",
  },
  {
    link: "Invest In Yourself",
    label: "Incentives/Birth Certificate",
    value: "Incentives/Birth Certificate",
  },
  {
    link: "Invest In Yourself",
    label: "Incentives/Bus",
    value: "Incentives/Bus",
  },
  {
    link: "Invest In Yourself",
    label: "Incentives/Childcare",
    value: "Incentives/Childcare",
  },
  {
    link: "Invest In Yourself",
    label: "Incentives/Clothes",
    value: "Incentives/Clothes",
  },
  {
    link: "Invest In Yourself",
    label: "Incentives/Education",
    value: "Incentives/Education",
  },
  {
    link: "Invest In Yourself",
    label: "Incentives/Equipment",
    value: "Incentives/Equipment",
  },
  {
    link: "Invest In Yourself",
    label: "Incentives Fingerprinting",
    value: "Incentives Fingerprinting",
  },
  {
    link: "Invest In Yourself",
    label: "Incentives/Food",
    value: "Incentives/Food",
  },
  {
    link: "Invest In Yourself",
    label: "Incentives/Gas Card",
    value: "Incentives/Gas Card",
  },
  {
    link: "Invest In Yourself",
    label: "Incentives/Gift Card",
    value: "Incentives/Gift Card",
  },
  {
    link: "Invest In Yourself",
    label: "Incentives/ID",
    value: "Incentives/ID",
  },
  {
    link: "Invest In Yourself",
    label: "Incentives/Rent",
    value: "Incentives/Rent",
  },
  {
    link: "Invest In Yourself",
    label: "Incentives/Rent App Fee",
    value: "Incentives/Rent App Fee",
  },
  {
    link: "Invest In Yourself",
    label: "Incentives/Rent Deposit",
    value: "Incentives/Rent Deposit",
  },
  {
    link: "Invest In Yourself",
    label: "Incentives/Gas Heat",
    value: "Incentives/Gas Heat",
  },
  {
    link: "Invest In Yourself",
    label: "Incentives/Electric",
    value: "Incentives/Electric",
  },
  {
    link: "Invest In Yourself",
    label: "Increased Savings = Yes",
    value: "Increased Savings = Yes",
  },
  { link: "Invest In Yourself", label: "Savings = No", value: "Savings = No" },
  {
    link: "Invest In Yourself",
    label: "Savings = Yes",
    value: "Savings = Yes",
  },
  { link: "Invest In Yourself", label: "Referral", value: "Referral" },
  {
    link: "Invest In Yourself",
    label: "Referral/Attended",
    value: "Referral/Attended",
  },
  {
    link: "Invest In Yourself",
    label: "Referral/Absent",
    value: "Referral/Absent",
  },
  { link: "Employment Services", label: "JSS", value: "JSS" },
  { link: "Employment Services", label: "Computer Lab", value: "Computer Lab" },
  { link: "Employment Services", label: "CM", value: "CM" },
  { link: "Employment Services", label: "Job Leads", value: "Job Leads" },
  { link: "Employment Services", label: "Resume", value: "Resume" },
  { link: "Education", label: "DC Enrolled", value: "DC Enrolled" },
  { link: "Education", label: "DC Completed", value: "DC Completed" },
  { link: "Education", label: "PearsonView", value: "PearsonView" },


];
export default options;
