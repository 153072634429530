import React, { Component } from "react";
import { Redirect } from "react-router";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import ReactTable from "react-table";
import fuzzyFilter from "../util";
import api from "../utils/api";
import PersonForm from "./person_form";
import NewPersonForm from "./new_person_form";
import SearchPeopleForm from "./modal_search_people_form";
import { CSVLink } from "react-csv";
import PasswordForm from "./modal_password_change";

class Home extends Component {
  state = {
    data: [],
    loadingData: false,
    redirect: null,
    selectedPerson: {},
    personFormVisible: false,
    newPersonFormVisible: false,
    passwordFormVisible: false,
    peopleSearchFormVisible: false,
    peopleSearch: false,
  };

  componentDidMount() {
    console.log("The Snake works!");
    this.loadData();
  }

  loadData = () => {
    this.setState({ loadingData: true });
    api.query("people").then((result) => {
      this.setState({ data: result.data, loadingData: false });
    });
  };

  showPersonEdit = (person_to_edit) => {
    this.setState({ personFormVisible: true, selectedPerson: person_to_edit });
  };

  togglePersonForm = () => {
    this.setState((prevState) => ({
      personFormVisible: !prevState.personFormVisible,
    }));
  };

  savePerson = (person, attributes) => {
    api.updatePerson(person, attributes).then(() => {
      this.togglePersonForm();
      this.loadData();
    });
  };

  saveNewPerson = (attributes) => {
    api.newPerson(attributes).then(() => {
      this.toggleNewPersonForm();
      this.loadData();
    });
  };

  showSearchedPeople = (
    firstName = "''",
    lastName = "''",
    dob = "''",
    household_id = -1
  ) => {
    this.setState({ loadingData: true, peopleSearch: true });
    let qryFilter = "";
    if (firstName !== "''") {
      qryFilter = "AND lower(p.first_name)=" + firstName;
    }
    if (lastName !== "''") {
      qryFilter += " AND lower(p.last_name)=" + lastName;
    }
    if (dob !== "''") {
      qryFilter += " AND to_char(p.dob, 'MM/DD/YYYY')=" + dob;
    }
    if (household_id !== -1 && household_id !== "") {
      qryFilter += " AND household_id=" + household_id;
    }
    api
      .query("search_people", {
        qryFilter: qryFilter,
      })
      .then((result) => {
        this.toggleSearchPeopleForm();
        this.setState({ data: result.data, loadingData: false });
      });
  };

  showNewPersonForm = () => {
    this.setState({ newPersonFormVisible: true });
  };

  showPeopleSearchForm = () => {
    this.setState({ peopleSearchFormVisible: true });
  };

  showPasswordForm = () => {
    this.setState({ passwordFormVisible: true });
  };

  savePassword = (oldPassword, NewPassword) => {
    api.updatePassword(oldPassword, NewPassword).then(() => {
      this.togglePasswordForm();
      this.loadData();
    });
  };

  toggleNewPersonForm = () => {
    this.setState((prevState) => ({
      newPersonFormVisible: !prevState.newPersonFormVisible,
    }));
  };

  toggleSearchPeopleForm = () => {
    this.setState((prevState) => ({
      peopleSearchFormVisible: !prevState.peopleSearchFormVisible,
    }));
  };

  togglePasswordForm = () => {
    this.setState((prevState) => ({
      passwordFormVisible: !prevState.passwordFormVisible,
    }));
  };

  CheckIn = (person, household) => {
    api.checkIn(person, household).then(() => {
      this.loadData();
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect push to={`/${this.state.redirect}`} />;
    }
    return (
      <div>
        <div className="row">
          <div className="col">
            <Button
              onClick={() => {
                this.showNewPersonForm();
              }}
              color="success"
            >
              Add New Person
            </Button>{" "}
            <Button
              onClick={() => {
                this.showPeopleSearchForm();
              }}
              color="info"
            >
              Search for a Person
            </Button>{" "}
            {this.state.peopleSearch && (
              <Button
                onClick={() => {
                  this.loadData();
                  this.setState({ peopleSearch: false });
                }}
                color="warning"
              >
                Remove Search
              </Button>
            )}
            <Button
              onClick={() => {
                this.showPasswordForm();
              }}
              color="primary"
            >
              Update Password
            </Button>
            <ReactTable
              style={{ textAlign: "center" }}
              data={this.state.data}
              loading={this.state.loadingData}
              filterable
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value
              }
              defaultPageSize={25}
              columns={[
                {
                  Header: "Search Table",
                  columns: [
                    {
                      Header: "Person Id",
                      accessor: "id",
                      maxWidth: "100",
                      show: false,
                    },
                    {
                      Header: "Household Id",
                      accessor: "household_id",
                      maxWidth: "100",
                    },
                    {
                      Header: "First",
                      accessor: "first_name",
                      maxWidth: "175",
                      filterMethod: fuzzyFilter,
                    },
                    {
                      Header: "Last",
                      accessor: "last_name",
                      maxWidth: "175",
                      filterMethod: fuzzyFilter,
                    },
                    {
                      Header: "DOB",
                      accessor: "dob",
                      maxWidth: "100",
                      filterMethod: fuzzyFilter,
                    },
                    {
                      Header: "Age",
                      accessor: "age",
                      maxWidth: "100",
                    },
                    {
                      Header: "Status",
                      accessor: "is_head",
                      maxWidth: "225",
                    },
                    {
                      Header: "Shredded",
                      accessor: "shredded",
                      show: false,
                    },
                    {
                      Header: "Checked In?",
                      accessor: "checked_in",
                      filterMethod: fuzzyFilter,
                      maxWidth: "100",
                    },
                    {
                      Header: "Actions",
                      Cell: (row) => {
                        return (
                          <>
                            <Button
                              onClick={() => {
                                this.props.onSelectPerson &&
                                  this.props.onSelectPerson(
                                    row.original.id,
                                    row.original.household_id,
                                    row.original.shredded
                                  );
                                this.setState({ redirect: "household" });
                              }}
                              style={{ backgroundColor: "#7030a0" }}
                            >
                              Household
                            </Button>{" "}
                            <Button
                              onClick={() => {
                                this.CheckIn(
                                  row.original.id,
                                  row.original.household_id
                                );
                              }}
                              style={{ backgroundColor: "#d90036" }}
                            >
                              CheckIn
                            </Button>{" "}
                            <Button
                              onClick={() => this.showPersonEdit(row.original)}
                              color="primary"
                            >
                              Update
                            </Button>
                          </>
                        );
                      },
                    },
                  ],
                },
              ]}
              className="details -striped -highlight"
            />
            <CSVLink data={this.state.data}>Download All Table Data</CSVLink>
          </div>
        </div>
        <Modal
          isOpen={this.state.personFormVisible}
          toggle={this.togglePersonForm}
          size="lg"
        >
          <ModalHeader toggle={this.togglePersonForm}>
            Update Person
          </ModalHeader>
          <ModalBody>
            <PersonForm
              person={this.state.selectedPerson}
              savePerson={this.savePerson}
            />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.newPersonFormVisible}
          toggle={this.toggleNewPersonForm}
          size="lg"
        >
          <ModalHeader toggle={this.toggleNewPersonForm}>
            New Person
          </ModalHeader>
          <ModalBody>
            <NewPersonForm saveNewPerson={this.saveNewPerson} />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.peopleSearchFormVisible}
          toggle={this.toggleSearchPeopleForm}
          size="lg"
          autoFocus={false}
        >
          <ModalHeader>People Search</ModalHeader>
          <ModalBody>
            <SearchPeopleForm showSearchedPeople={this.showSearchedPeople} />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.passwordFormVisible}
          toggle={this.togglePasswordForm}
          size="lg"
        >
          <ModalHeader>Password Update Form</ModalHeader>
          <ModalBody>
            <PasswordForm savePassword={this.savePassword} />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
export default Home;
