import React, { Component } from "react";
import {
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Row,
  Col
} from "reactstrap";
import "react-toggle/style.css";

class NotesForm extends Component {
  static defaultProps = {
    household: {}
  };

  state = {
    household_id: this.props.household.household_id,
    notes: this.props.household.notes
  };

  handleChange = event => {
    const target = event.target.id;
    const value = event.target.value;
    this.setState({ [target]: value });
  };

  componentDidMount() {
    console.log(this.props);
  }

  render() {
    return (
      <Container>
        <Form>
          <FormGroup>
            <Label for="notes">Notes</Label>
            <Input
              type="textarea"
              name="notes"
              id="notes"
              placeholder=""
              value={this.state.notes}
              onChange={this.handleChange}
            />
          </FormGroup>
          <Button
            onClick={e => {
              e.preventDefault();
              this.props.saveHousehold(this.state.household_id, this.state);
            }}
          >
            Save
          </Button>
        </Form>
      </Container>
    );
  }
}

export default NotesForm;
