import React, { Component } from "react";
import Nav from "./nav";
import Home from "./home";
import api from "../utils/api";
import LoginForm from "./loginform";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import Household from "./household";
import ReportTest from "./report_test";
import Users from "./users";

const navLinksSuperUser = [
  { label: "Home", path: "/" },
  { label: "Report Center", path: "/report_center" },
  { label: "Users", path: "/users" }
];

const navLinksVolunteer = [
  { label: "Home", path: "/" },
  { label: "Report Center", path: "/report_center" }
];

class Main extends Component {
  state = {
    loggedIn: api.is_logged_in(),
    user: api.logged_in_user(),
    userRole: api.logged_in_user_role(),
    message: "",
    loaded: {
      last_load_date: "1/1/1900"
    },
    selected_person_id: 1,
    selected_household_id: 2
  };

  constructor(props) {
    super(props);
    api.set_handleLogout(this.handleLogout);
  }

  componentDidMount() {
    console.log(this.state.userRole);
  }

  handleLogin = data => {
    api.authenticate(data).then(isLoggedIn => {
      const message = isLoggedIn[0] ? "" : "Failed logging in";
      this.setState({
        user: data.user,
        loggedIn: isLoggedIn[0],
        message: message,
        userRole: isLoggedIn[1]
      });
    });
  };

  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route path="/login" exact>
              <LoginForm
                onSubmit={this.handleLogin}
                message={this.state.message}
                title="Irving Cares"
              />
            </Route>
            <Route path="/">
              <Nav
                links={
                  this.state.userRole === "superuser"
                    ? navLinksSuperUser
                    : navLinksVolunteer
                }
                user={this.state.user}
                loaded={this.state.loaded}
                brand="Irving Cares DB"
              />
            </Route>
          </Switch>
          <Switch>
            <PrivateRoute exact path="/">
              <Home
                onSelectPerson={(selected_person_id, selected_household_id) =>
                  this.setState({
                    selected_person_id,
                    selected_household_id
                  })
                }
              />
            </PrivateRoute>
            <PrivateRoute path="/household">
              <Household
                person_id={this.state.selected_person_id}
                household_id={this.state.selected_household_id}
              />
            </PrivateRoute>
            <PrivateRoute path="/report_center">
              <ReportTest></ReportTest>
            </PrivateRoute>
            {this.state.userRole === "superuser" && (
              <PrivateRoute path="/users">
                <Users />
              </PrivateRoute>
            )}
            <PrivateRoute path="/login"> </PrivateRoute>
            <Route>
              <div>That page does not exist yet</div>
            </Route>
          </Switch>
        </div>
      </Router>
    );
  }
}
export default Main;

const PrivateRoute = ({ children, ...rest }) => (
  <div className="container-fluid">
    <Route
      {...rest}
      render={() => (api.is_logged_in() ? children : <Redirect to="/login" />)}
    />
  </div>
);
