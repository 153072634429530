import React, { Component } from "react";
import {
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import "react-toggle/style.css";

class DisabilityForm extends Component {
  static defaultProps = {
    household: {}
  };

  state = {
    allow_input: this.props.household.disabled_count > 0 ? "Yes" : "No",
    disabled_count: this.props.household.disabled_count
  };

  handleChange = event => {
    const target = event.target.id;
    const value = event.target.value;
    this.setState({ [target]: value });
  };

  render() {
    return (
      <Container>
        <Form>
          <Row form>
            <Col>
              <FormGroup>
                <Label for="allow_input">
                  Are you or a family member Federal disabled:
                </Label>
                <Input
                  type="select"
                  name="allow_input"
                  id="allow_input"
                  value={this.state.allow_input}
                  onChange={this.handleChange}
                >
                  <option>Yes</option>
                  <option>No</option>
                </Input>
              </FormGroup>
            </Col>
            {this.state.allow_input === "Yes" ? (
              <Col>
                <FormGroup>
                  <Label for="disabled_count">Disability Count</Label>
                  <Input
                    type="select"
                    name="disabled_count"
                    id="disabled_count"
                    value={this.state.disabled_count}
                    onChange={this.handleChange}
                  >
                    <option>0</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                  </Input>
                </FormGroup>
              </Col>
            ) : (
              <Col></Col>
            )}
          </Row>
          <Row>
            <Col>
              {" "}
              <Button
                onClick={e => {
                  e.preventDefault();
                  this.props.saveDisabled(
                    this.props.household.household_id,
                    this.state.allow_input === "Yes"
                      ? this.state.disabled_count
                      : 0
                  );
                }}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    );
  }
}

export default DisabilityForm;
