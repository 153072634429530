import React from "react";
import {
  Button,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
  NavItem
} from "reactstrap";
import api from "../utils/api";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";

export default class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    const { links, user } = this.props;
    return (
      <div>
        <Navbar color="light" light expand="md">
          <NavbarBrand href="/">{this.props.brand}</NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavLinks links={links} />
            </Nav>
            <ul className="navbar-nav ml-auto">
              {user && (
                <li className="nav-item navbar-text">Logged in As: {user}</li>
              )}
              <LogoutLink />
            </ul>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}
NavBar.propTypes = {
  links: PropTypes.array,
  disableLogout: PropTypes.bool
};

NavBar.defaultProps = {
  disableLogout: false
};
function NavLinks({ links }) {
  return links.map((link, index) => <RouterLink key={index} {...link} />);
}

NavLinks.propTypes = {
  links: PropTypes.array
};

NavLinks.defaultProps = {
  links: [{}, {}, {}]
};

function RouterLink({ label, path }) {
  return (
    <Button size="sm" style={{ backgroundColor: "#F7F7F7" }}>
      <NavItem>
        <NavLink
          tag={Link}
          to={path}
          active={window.location.pathname === path}
        >
          {label}
        </NavLink>
      </NavItem>
    </Button>
  );
}

RouterLink.propTypes = {
  label: PropTypes.string,
  path: PropTypes.string
};

RouterLink.defaultProps = {
  label: "Nowhere",
  path: "nowhere"
};

function LogoutLink() {
  return (
    <NavItem className="right">
      <NavLink tag={Link} to="/login" onClick={() => api.logout()}>
        Logout
      </NavLink>
    </NavItem>
  );
}
