import React, { Component } from "react";
import {
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Button,
  Row,
  Col
} from "reactstrap";
import "react-toggle/style.css";
import NumberFormat from "react-number-format";
import Moment from "moment";

class PersonSearchForm extends Component {
  state = {
    firstName: "",
    lastName: "",
    dob: "",
    household_id: null,
    validateDOB: true,
    qryFilter: ""
  };

  constructor(props) {
    super(props);
    this.nameInput = React.createRef();
  }

  componentDidMount() {
    console.log(this.nameInput.current);
    this.nameInput.current.focus();
  }

  handleChange = event => {
    const target = event.target.id;
    const value = event.target.value;
    this.setState({ [target]: value });
    this.setState({
      validateDOB:
        target === "dob"
          ? Moment(event.target.value, "MM/DD/YYYY", true) <= Moment() &&
            Moment(event.target.value, "MM/DD/YYYY", true).isValid()
          : this.state.validateDOB
    });
  };

  enterPressed(event) {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.props.showSearchedPeople(
        "'" + this.state.firstName.toLowerCase() + "'",
        "'" + this.state.lastName.toLowerCase() + "'",
        "'" + this.state.dob.toLowerCase() + "'",
        this.state.household_id === null ? -1 : this.state.household_id
      );
    }
  }

  render() {
    return (
      <Container>
        <Form>
          <Row>
            <Col>
              <FormGroup>
                <Label for="household_id">Household ID</Label>
                <Input
                  type="text"
                  name="household_id"
                  id="household_id"
                  placeholder=""
                  value={this.state.household_id}
                  onChange={this.handleChange}
                  onKeyPress={this.enterPressed.bind(this)}
                  innerRef={this.nameInput}
                />
              </FormGroup>
            </Col>{" "}
            <Col>
              <FormGroup>
                <Label for="firstName">First name</Label>
                <Input
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder=""
                  value={this.state.firstName}
                  onChange={this.handleChange}
                  onKeyPress={this.enterPressed.bind(this)}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="lastName">Last name</Label>
                <Input
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder=""
                  value={this.state.lastName}
                  onChange={this.handleChange}
                  onKeyPress={this.enterPressed.bind(this)}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="dob">Birthdate</Label>
                <NumberFormat
                  type="text"
                  name="dob"
                  id="dob"
                  placeholder="1/1/1900"
                  onChange={this.handleChange}
                  customInput={Input}
                  format="##/##/####"
                  mask="_"
                  invalid={!this.state.validateDOB}
                  onKeyPress={this.enterPressed.bind(this)}
                />
                <FormFeedback invalid>Please Check the Birthdate!</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Button
            onClick={e => {
              e.preventDefault();
              this.props.showSearchedPeople(
                "'" + this.state.firstName.toLowerCase() + "'",
                "'" + this.state.lastName.toLowerCase() + "'",
                "'" + this.state.dob.toLowerCase() + "'",
                this.state.household_id === null ? -1 : this.state.household_id
              );
            }}
          >
            Search
          </Button>
        </Form>
      </Container>
    );
  }
}

export default PersonSearchForm;
