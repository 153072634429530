import React, { Component } from "react";
import {
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
} from "reactstrap";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "react-toggle/style.css";
import NumberFormat from "react-number-format";
class IncomeForm extends Component {
  static defaultProps = {
    household: {},
  };

  state = {};

  handleChange = (event) => {
    const target = event.target.id;
    const value = event.target.value;
    this.setState({ [target]: value.replace("$", "").replace(",", "") });
  };

  render() {
    const ifNaN = (value) => {
      return isNaN(value) ? 0 : value;
    };
    return (
      <Container>
        <Form>
          <Row form>
            <Col>
              <FormGroup>
                <Label for="monthly_disability_income">
                  Monthly Disability Income
                </Label>
                <NumberFormat
                  type="integer"
                  name="monthly_disability_income"
                  id="monthly_disability_income"
                  thousandSeparator={true}
                  prefix={"$"}
                  customInput={Input}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="monthly_social_security_income">
                  Monthly Social Security Income
                </Label>
                <NumberFormat
                  type="integer"
                  name="monthly_social_security_income"
                  id="monthly_social_security_income"
                  thousandSeparator={true}
                  prefix={"$"}
                  customInput={Input}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="monthly_child_support_income">
                  Monthly Child Support Income
                </Label>
                <NumberFormat
                  type="integer"
                  name="monthly_child_support_income"
                  id="monthly_child_support_income"
                  thousandSeparator={true}
                  prefix={"$"}
                  customInput={Input}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="monthly_employment_income">
                  Monthly Employment Income
                </Label>
                <NumberFormat
                  type="integer"
                  name="monthly_employment_income"
                  id="monthly_employment_income"
                  thousandSeparator={true}
                  prefix={"$"}
                  customInput={Input}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="annual_household_income">
                  Annual Household Income
                </Label>
                <NumberFormat
                  type="integer"
                  name="annual_household_income"
                  id="annual_household_income"
                  thousandSeparator={true}
                  prefix={"$"}
                  customInput={Input}
                  value={
                    ifNaN(parseInt(this.state.monthly_social_security_income)) +
                      ifNaN(parseInt(this.state.monthly_employment_income)) +
                      ifNaN(parseInt(this.state.monthly_disability_income)) +
                      ifNaN(
                        parseInt(this.state.monthly_child_support_income)
                      ) ===
                    0
                      ? this.props.household.household_income
                      : "$" +
                        12 *
                          (ifNaN(
                            parseInt(this.state.monthly_social_security_income)
                          ) +
                            ifNaN(
                              parseInt(this.state.monthly_employment_income)
                            ) +
                            ifNaN(
                              parseInt(this.state.monthly_disability_income)
                            ) +
                            ifNaN(
                              parseInt(this.state.monthly_child_support_income)
                            ))
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              {" "}
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  this.props.saveIncome(
                    this.props.household.id,
                    12 *
                      (ifNaN(
                        parseInt(this.state.monthly_social_security_income)
                      ) +
                        ifNaN(parseInt(this.state.monthly_employment_income)) +
                        ifNaN(parseInt(this.state.monthly_disability_income)) +
                        ifNaN(
                          parseInt(this.state.monthly_child_support_income)
                        )),

                    parseInt(this.state.monthly_disability_income),
                    parseInt(this.state.monthly_social_security_income),
                    parseInt(this.state.monthly_child_support_income),
                    parseInt(this.state.monthly_employment_income)
                  );
                }}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    );
  }
}

export default IncomeForm;

const CustomInput = ({ date, ...others }) => (
  <Input {...others} value={moment(date).format("MM/DD/YYYY")} />
);
