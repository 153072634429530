import React, { Component } from "react";
import {
  Container,
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import NumberFormat from "react-number-format";
import IncomeForm from "./modal_new_person_income_form";
import Moment from "moment";

class NewPersonForm extends Component {
  static defaultProps = {
    person: {},
  };

  state = {
    first_name: this.props.person.first_name,
    middle_name: this.props.person.middle_name,
    last_name: this.props.person.last_name,
    personal_id: this.props.person.personal_id,
    personal_id_desc: this.props.person.personal_id_desc,
    email: this.props.person.email,
    home_phone: this.props.person.home_phone || "Unknown",
    marital_status: this.props.person.marital_status || "Unknown",
    race: this.props.person.race || "Unknown",
    ethnicity: this.props.person.ethnicity || "Unknown",
    gender: this.props.person.gender || "Unknown",
    limited_english: this.props.person.limited_english,
    veteran: this.props.person.veteran,
    notes: this.props.person.notes,
    social_security_number: this.props.person.social_security_number,
    dob: this.props.person.dob || "09/21/1980",
    address: this.props.person.address || "",
    apt_no: this.props.person.apt_no || "",
    city: this.props.person.city || "Unknown",
    state: this.props.person.state || "Unknown",
    zip_code: this.props.person.zip_code || "Unknown",
    annual_household_income: this.props.person.annual_household_income || -99,
    monthly_disability_income:
      this.props.person.monthly_disability_income || -99,
    monthly_child_support_income:
      this.props.person.monthly_child_support_income || -99,
    monthly_employment_income:
      this.props.person.monthly_employment_income || -99,
    monthly_social_security_income:
      this.props.person.monthly_social_security_income || -99,
    proof_of_residency: this.props.person.proof_of_residency || "Unknown",
    incomeFormVisible: false,
    selectedIncome: {},
    validateDOB: true,
    notes: this.props.person.notes || "",
  };

  handleChange = (event) => {
    const target = event.target.id;
    const value = event.target.value;
    this.setState({ [target]: value.replace("#", "") });
    this.setState({
      validateDOB:
        target === "dob"
          ? Moment(event.target.value, "MM/DD/YYYY", true) <= Moment() &&
            Moment(event.target.value, "MM/DD/YYYY", true).isValid()
          : this.state.validateDOB,
    });
    console.log(Moment(event.target.value, "MM/DD/YYYY", true).isValid());
  };

  handleDateChange = (date) => {
    this.setState({
      dob: date,
    });
  };

  showIncome = (income_to_set) => {
    this.setState({
      incomeFormVisible: true,
      selectedIncome: income_to_set,
    });
  };

  toggleIncomeForm = () => {
    this.setState((prevState) => ({
      incomeFormVisible: !prevState.incomeFormVisible,
    }));
  };

  saveIncome = (
    household_id,
    annual_household_income,
    monthly_disability_income,
    monthly_social_security_income,
    monthly_child_support_income,
    monthly_employment_income
  ) => {
    this.toggleIncomeForm();

    this.setState({
      annual_household_income: annual_household_income,
      monthly_disability_income: monthly_disability_income,
      monthly_social_security_income: monthly_social_security_income,
      monthly_child_support_income: monthly_child_support_income,
      monthly_employment_income: monthly_employment_income,
    });
  };

  handleChangeOther = (event) => {
    const value = event.target.value;
    this.setState({ personal_id_desc: value });
  };

  handleChangeOtherRace = (event) => {
    const value = event.target.value;
    this.setState({ race: value });
  };

  render() {
    return (
      <Container>
        <Form>
          <Row>
            <Col>
              <FormGroup>
                <Label for="first_name">First name</Label>
                <Input
                  type="text"
                  name="first_name"
                  id="first_name"
                  placeholder="First Name"
                  value={this.state.first_name}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="middle_name">Middle name</Label>
                <Input
                  type="text"
                  name="middle_name"
                  id="middle_name"
                  placeholder="Middle Name"
                  onChange={this.handleChange}
                  value={this.state.middle_name}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="last_name">Last name</Label>
                <Input
                  type="text"
                  name="last_name"
                  id="last_name"
                  placeholder="Last Name"
                  onChange={this.handleChange}
                  value={this.state.last_name}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="dob">Birthdate</Label>
                <NumberFormat
                  type="text"
                  name="dob"
                  id="dob"
                  placeholder="1/1/1900"
                  onChange={this.handleChange}
                  customInput={Input}
                  format="##/##/####"
                  mask="_"
                  invalid={!this.state.validateDOB}
                />
                <FormFeedback invalid>Please Check the Birthdate!</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="home_phone">Head of Household Phone</Label>
                <NumberFormat
                  type="text"
                  name="home_phone"
                  id="home_phone"
                  placeholder="(555) 555-5555"
                  value={this.state.home_phone}
                  onChange={this.handleChange}
                  format="(###) ###-####"
                  mask="_"
                  customInput={Input}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label for="social_security_number">SSN</Label>
                <NumberFormat
                  type="text"
                  name="social_security_number"
                  id="social_security_number"
                  placeholder="555-55-5555"
                  value={this.state.social_security_number}
                  onChange={this.handleChange}
                  format="###-##-####"
                  mask="_"
                  customInput={Input}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="personal_id">Personal ID</Label>
                <NumberFormat
                  type="text"
                  name="personal_id"
                  id="personal_id"
                  placeholder="Unknown"
                  value={this.state.personal_id}
                  onChange={this.handleChange}
                  customInput={Input}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="personal_id_desc">Personal ID Desc</Label>
                <Input
                  type="select"
                  name="personal_id_desc"
                  id="personal_id_desc"
                  value={this.state.personal_id_desc}
                  onChange={this.handleChange}
                >
                  {["TXID", "TXDL"].indexOf(this.state.personal_id_desc) >
                  -1 ? (
                    <>
                      <option>TXDL</option>
                      <option>TXID</option>
                      <option>Other</option>
                    </>
                  ) : (
                    <>
                      <option>Other</option>
                      <option>TXDL</option>
                      <option>TXID</option>
                    </>
                  )}
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="personal_id_desc">Enter Other Value:</Label>
                {this.state.personal_id_desc !== "TXDL" ? (
                  <Input
                    type="text"
                    name="personal_id_desc_other"
                    id="personal_id_desc_other"
                    value={this.state.personal_id_desc}
                    onChange={this.handleChangeOther}
                  ></Input>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder=""
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="income">Income</Label>
                <NumberFormat
                  onClick={() =>
                    this.showIncome(this.state.annual_household_income)
                  }
                  type="text"
                  name="income"
                  id="income"
                  thousandSeparator={true}
                  prefix={"$"}
                  customInput={Input}
                  value={this.state.annual_household_income}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label for="marital_status">Marital status</Label>
                <Input
                  type="select"
                  name="marital_status"
                  id="marital_status"
                  placeholder=""
                  value={this.state.marital_status}
                  onChange={this.handleChange}
                >
                  <option>Unknown</option>
                  <option>Single</option>
                  <option>Married</option>
                  <option>Divorced</option>
                  <option>Separated</option>
                  <option>Cohabitating</option>
                  <option>Widowed</option>
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="gender">Gender</Label>
                <Input
                  type="select"
                  name="gender"
                  id="gender"
                  value={this.state.gender}
                  onChange={this.handleChange}
                >
                  <option>Male</option>
                  <option>Female</option>
                  <option>Other</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="race">Race</Label>
                <Input
                  type="select"
                  name="race"
                  id="race"
                  placeholder=""
                  value={this.state.race}
                  onChange={this.handleChange}
                >
                  <option>Unknown</option>
                  <option>American Indian/Alaska Native</option>
                  <option>Asian</option>
                  <option>Black/African American</option>
                  <option>White</option>
                  <option>Native Hawaiian/Pacific Island</option>
                  <option>Latino</option>
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="ethnicity">Ethnicity</Label>
                <Input
                  type="select"
                  name="ethnicity"
                  id="ethnicity"
                  placeholder=""
                  value={this.state.ethnicity}
                  onChange={this.handleChange}
                >
                  <option>Unknown</option>
                  <option>Hispanic/Latino</option>
                  <option>non-Hispanic/Latino</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Toggle
                  id="limited_english"
                  defaultChecked={this.state.limited_english}
                  name="limited_english"
                  value="1"
                  onChange={() =>
                    this.setState({
                      limited_english: this.state.limited_english === 1 ? 0 : 1,
                    })
                  }
                />
                <Label for="limited_english">Limited English</Label>
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Toggle
                  id="veteran"
                  defaultChecked={this.state.veteran}
                  name="veteran"
                  value="1"
                  onChange={() =>
                    this.setState({ veteran: this.state.veteran === 1 ? 0 : 1 })
                  }
                />
                <Label for="veteran">Veteran</Label>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label for="notes">Notes</Label>
            <Input
              type="textarea"
              name="notes"
              id="notes"
              placeholder=""
              value={this.state.notes}
              onChange={this.handleChange}
            />
          </FormGroup>
          <Row>
            <Col>
              <FormGroup>
                <Label for="address">Address</Label>
                <Input
                  type="text"
                  name="address"
                  id="address"
                  placeholder="Unknown"
                  value={this.state.address}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="state">Proof of Residency</Label>
                <Input
                  type="select"
                  name="state"
                  id="proof_of_residency"
                  placeholder="Unknown"
                  value={this.state.proof_of_residency}
                  onChange={this.handleChange}
                >
                  <option>Unknown</option>
                  <option>Utility Bill</option>
                  <option>TX Drivers Licence</option>
                  <option>Texas ID</option>
                  <option>Brighter Tomorrows</option>
                  <option>Letter</option>
                  <option>Lease</option>
                  <option>ID - Other</option>
                  <option>Homeless</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Col>
            <FormGroup>
              <Label for="apt_no">Apt No.</Label>
              <Input
                type="text"
                name="apt_no"
                id="apt_no"
                placeholder="Unknown"
                value={this.state.apt_no}
                onChange={this.handleChange}
              />
            </FormGroup>
          </Col>
          <Row>
            <Col>
              <FormGroup>
                <Label for="city">City</Label>
                <Input
                  type="select"
                  name="city"
                  id="city"
                  placeholder="Unknown"
                  value={this.state.city}
                  onChange={this.handleChange}
                >
                  {" "}
                  <option>Unknown</option>
                  <option>Irving</option>
                  <option>Other</option>
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="state">State</Label>
                <Input
                  type="select"
                  name="state"
                  id="state"
                  placeholder="Unknown"
                  value={this.state.state}
                  onChange={this.handleChange}
                >
                  <option>Unknown</option>
                  <option>TX</option>
                  <option>Other</option>
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="zip_code">Zip Code</Label>
                <Input
                  type="select"
                  name="zip_code"
                  id="zip_code"
                  placeholder="Unknown"
                  value={this.state.zip_code}
                  onChange={this.handleChange}
                >
                  <option>Unknown</option>
                  <option>75014</option>
                  <option>75015</option>
                  <option>75016</option>
                  <option>75017</option>
                  <option>75038</option>
                  <option>75039</option>
                  <option>75060</option>
                  <option>75061</option>
                  <option>75062</option>
                  <option>75063</option>
                  <option>75064</option>
                  <option>HOMELESS</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Button
            onClick={(e) => {
              e.preventDefault();
              this.props.saveNewPerson(this.state);
            }}
          >
            Save
          </Button>
          <Modal
            isOpen={this.state.incomeFormVisible}
            toggle={this.toggleIncomeForm}
            size="sm"
          >
            {" "}
            <ModalHeader toggle={this.toggleIncomeForm}>Update</ModalHeader>
            <ModalBody>
              <IncomeForm
                household={this.state.selectedIncome}
                saveIncome={this.saveIncome}
              />
            </ModalBody>
          </Modal>
        </Form>
      </Container>
    );
  }
}

export default NewPersonForm;
