import React, { Component } from "react";
import { PDFViewer, Document, Page, Text, View } from "@react-pdf/renderer";
import api from "../utils/api";
import {
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
  Spinner,
} from "reactstrap";
import NumberFormat from "react-number-format";
import { CSVLink } from "react-csv";
import ReactTable from "react-table";
import Moment from "moment";

class ReportTest extends Component {
  state = {
    startDate: null,
    endDate: null,
    ytdStartDate: null,
    clientSummaryData: [],
    descriptionSummaryData: [],
    download: false,
    showPDF: false,
    showTable: false,
    showLink: false,
    headers: [""],
    clientSummaryHeaders: [""],
    descriptionSummaryHeaders: [""],
    peopleServedSummaryHeaders: [""],
    peopleServedSummaryData: [""],
    report: "rpt_invest_in_yourself",
    missingData: false,
    reload: false,
    validateStartDate: true,
    validateEndDate: true,
    validateYtdStartDate: true,
    qryFilter: "",
    program: "all",
  };

  csvLink = React.createRef();

  loadReportData = (report) => {
    this.setState({
      showPDF: false,
      showTable: false,
      showLink: false,
      loading: true,
      missingData: false,
      reload: true,
    });
    api
      .query(`${report}`, {
        startDate: this.state.startDate || "'1/1/1900'",
        endDate: this.state.endDate || "'1/1/1900'",
        ytdStartDate: this.state.ytdStartDate || "'1/1/1900'",
        qryFilter: this.state.qryFilter || "",
        download: true,
      })
      .then((result) => {
        result.data[0] == null
          ? this.setState({ missingData: true, loading: false })
          : this.setState({
              data: result.data,
              showPDF: [
                "rpt_unduplicated",
                "rpt_income_report",
                "rpt_first_time_visitors",
                "rpt_minor_by_program", 
                "rpt_minor_by_description", 
                "rpt_senior_by_program", 
                "rpt_senior_by_description", 
                "weekly_food_pantry_visits"

              ].includes(String(report))
                ? false
                : true,
              showTable: true,
              showLink: true,
              headers: Object.getOwnPropertyNames(result.data[0]),
              loading: false,
              reload: true,
            });
      });
  };

  loadClientSummaryReportData = (report) => {
    this.setState({ showPDF: false, loading: true, missingData: false });
    api
      .query(`${report}_client_summary`, {
        startDate: this.state.startDate || "'1/1/1900'",
        endDate: this.state.endDate || "'1/1/1900'",
        ytdStartDate: this.state.ytdStartDate || "'1/1/1900'",
        download: true,
      })
      .then((result) => {
        result.data[0] == null
          ? this.setState({ missingData: true, loading: false })
          : this.setState({
              clientSummaryData: result.data,
              showPDF: true,
              clientSummaryHeaders: Object.getOwnPropertyNames(result.data[0]),
              loading: false,
            });
      });
  };

  loadDescriptionSummaryReportData = (report) => {
    this.setState({ showPDF: false, loading: true, missingData: false });
    api
      .query(`${report}_description_summary`, {
        startDate: this.state.startDate || "'1/1/1900'",
        endDate: this.state.endDate || "'1/1/1900'",
        ytdStartDate: this.state.ytdStartDate || "'1/1/1900'",
        download: true,
      })
      .then((result) => {
        result.data[0] == null
          ? this.setState({ missingData: true, loading: false })
          : this.setState({
              descriptionSummaryData: result.data,
              showPDF: true,
              descriptionSummaryHeaders: Object.getOwnPropertyNames(
                result.data[0]
              ),
              loading: false,
            });
      });
  };

  loadFoodPeopleServedSummary = (report) => {
    this.setState({ showPDF: false, loading: true, missingData: false });
    api
      .query(`${report}_people_served_summary`, {
        startDate: this.state.startDate || "'1/1/1900'",
        endDate: this.state.endDate || "'1/1/1900'",
        ytdStartDate: this.state.ytdStartDate || "'1/1/1900'",
        download: true,
      })
      .then((result) => {
        result.data[0] == null
          ? this.setState({ missingData: true, loading: false })
          : this.setState({
              peopleServedSummaryData: result.data,
              showPDF: true,
              peopleServedSummaryHeaders: Object.getOwnPropertyNames(
                result.data[0]
              ),
              loading: false,
            });
      });
  };

  handleDateChange = (event) => {
    const target = event.target.id;
    const value = "'" + event.target.value + "'";
    this.setState({ [target]: value });
    this.setState({ reload: false });
    this.setState({
      showPDF:
        Moment(value).isValid() &&
        Moment(this.state.startDate).isValid() &&
        Moment(this.state.endDate).isValid() &&
        Moment(this.state.ytdStartDate).isValid() &&
        Moment(this.state.ytdStartDate) <= Moment(this.state.startDate)
          ? true
          : false,
    });
    this.setState({
      validateStartDate:
        target === "startDate"
          ? Moment(event.target.value, "MM/DD/YYYY", true) <= Moment() &&
            Moment(event.target.value, "MM/DD/YYYY", true).isValid()
          : this.state.validateStartDate,
      validateEndDate:
        target === "endDate"
          ? Moment(event.target.value, "MM/DD/YYYY", true) <= Moment() &&
            Moment(event.target.value, "MM/DD/YYYY", true).isValid()
          : this.state.validateEndDate,
      validateYtdStartDate:
        target === "ytdStartDate"
          ? Moment(event.target.value, "MM/DD/YYYY", true) <= Moment() &&
            Moment(event.target.value, "MM/DD/YYYY", true).isValid() &&
            Moment(event.target.value, "MM/DD/YYYY", true) <=
              Moment(this.state.startDate)
          : this.state.validateYtdStartDate,
    });
  };

  handleReportChange = (event) => {
    const target = event.target.id;
    const value = event.target.value;
    this.setState({ [target]: value });
    this.loadReportData(value);
    this.loadClientSummaryReportData(value);
    this.loadDescriptionSummaryReportData(value);
    this.loadFoodPeopleServedSummary(value);
  };

  handleChange = (event) => {
    const target = event.target.id;
    const value = event.target.value;
    this.setState({ [target]: value });
    switch (value) {
      case "All":
        this.setState({ qryFilter: "" });
        break;
      case "Emergency Assistance":
        this.setState({
          qryFilter:
            "and program in ('Food', 'Financial', 'Information and Referral', 'Christmas Assistance', 'Holiday Assistance')",
        });
        break;
      default:
        this.setState({ qryFilter: "and program = '" + value + "'" });
        break;
    }
  };

  getTrProps = (state, rowInfo, instance) => {
    if (rowInfo) {
      return {
        style: {
          background:
            String(rowInfo.row.Metric).includes("Summary") ||
            String(rowInfo.row.Program).includes("SUBTOTAL")
              ? "LightGrey"
              : "transparent",
          textAlign: "center",
        },
      };
    }
    return {};
  };

  render() {
    let reportTitle = null;

    const columns = this.state.headers.map((header) => {
      return { Header: header, accessor: header };
    });

    switch (this.state.report) {
      case "rpt_food":
        reportTitle = "Food Program";
        break;
      case "rpt_financial":
        reportTitle = "Financial Program";
        break;
      case "rpt_information_referral":
        reportTitle = "Information and Referral Program";
        break;
      case "rpt_limited_english_proficiency":
        reportTitle = "Limited English Proficiency";
        break;
      case "rpt_veteran":
        reportTitle = "Veteran Report";
        break;
      case "rpt_senior":
          reportTitle = "Senior Report";
        break;
      case "rpt_disability":
        reportTitle = "Disability Report";
        break;
      case "rpt_invest_in_yourself":
        reportTitle = "Invest In Yourself Report";
        break;
      case "rpt_unduplicated":
        reportTitle = "Unduplicated All Report";
        break;
      default:
        reportTitle = "Missing Title";
    }

    let showDescriptionSummary = null;

    switch (this.state.report) {
      case "rpt_food":
        showDescriptionSummary = 1;
        break;
      case "rpt_financial":
        showDescriptionSummary = 1;
        break;
      case "rpt_information_referral":
        showDescriptionSummary = 1;
        break;
      case "rpt_limited_english_proficiency":
        showDescriptionSummary = 1;
        break;
      case "rpt_veteran":
        showDescriptionSummary = 1;
        break;
      case "rpt_senior":
          showDescriptionSummary = 1;
        break;
      case "rpt_disability":
        showDescriptionSummary = 1;
        break;
      case "rpt_invest_in_yourself":
        showDescriptionSummary = 1;
        break;
      default:
        showDescriptionSummary = 0;
    }

    let showPeopleServedSummary = null;

    switch (this.state.report) {
      case "rpt_food":
        showPeopleServedSummary = 1;
        break;
      default:
        showPeopleServedSummary = 0;
    }

    let defaultPageSize = null;

    switch (this.state.report) {
      case "rpt_unduplicated":
        defaultPageSize = 64;
        break;
      default:
        defaultPageSize = 20;
    }

    const styles = {
      body: {
        padding: 10,
        paddingBottom: 65,
      },
      table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        padding: 10,
      },
      tableRow: {
        margin: "auto",
        flexDirection: "row",
      },
      tableTitle: {
        margin: "auto",
        flexDirection: "row",
        padding: 25,
        fontSize: 12,
      },
      reportTitle: {
        margin: "auto",
        padding: 50,
        textAlign: "center",
      },
      tableDescColHeader: {
        backgroundColor: "lightblue",
        width: `${100 / this.state.descriptionSummaryHeaders.length}%`,
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderBottomColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
      },
      tableDescCol: {
        width: `${100 / this.state.descriptionSummaryHeaders.length}%`,
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
      },
      tableSummColHeader: {
        backgroundColor: "lightblue",
        width: `${100 / this.state.clientSummaryHeaders.length}%`,
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderBottomColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
      },
      tableSummCol: {
        width: `${100 / this.state.clientSummaryHeaders.length}%`,
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
      },
      tableServColHeader: {
        backgroundColor: "lightblue",
        width: `${100 / this.state.peopleServedSummaryHeaders.length}%`,
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderBottomColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
      },
      tableServCol: {
        width: `${100 / this.state.peopleServedSummaryHeaders.length}%`,
        borderStyle: "solid",
        borderColor: "#bfbfbf",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
      },
      tableCellHeader: {
        margin: 5,
        fontSize: 8,
        fontWeight: 500,
      },
      tableCell: {
        margin: 5,
        fontSize: 8,
      },
      pageNumber: {
        position: "absolute",
        fontSize: 8,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
      },
    };

    const ClientSummaryHeader = () =>
      this.state.clientSummaryHeaders.map((p) => (
        <View style={styles.tableSummColHeader}>
          <Text style={styles.tableCellHeader} render={() => `${p}`}></Text>
        </View>
      ));

    const ClientSummaryTable = () =>
      this.state.clientSummaryData.map((d) => (
        <View style={styles.tableRow}>
          {this.state.clientSummaryHeaders.map((h) => (
            <View style={styles.tableSummCol}>
              <Text style={styles.tableCell} render={() => `${d[h]}`}></Text>
            </View>
          ))}
        </View>
      ));

    const DescriptionSummaryHeader = () =>
      this.state.descriptionSummaryHeaders.map((p) => (
        <View style={styles.tableDescColHeader}>
          <Text style={styles.tableCellHeader} render={() => `${p}`}></Text>
        </View>
      ));

    const DescriptionSummaryTable = () =>
      this.state.descriptionSummaryData.map((d) => (
        <View style={styles.tableRow}>
          {this.state.descriptionSummaryHeaders.map((h) => (
            <View style={styles.tableDescCol}>
              <Text style={styles.tableCell} render={() => `${d[h]}`}></Text>
            </View>
          ))}
        </View>
      ));

    const PeopleServedSummaryHeader = () =>
      this.state.peopleServedSummaryHeaders.map((p) => (
        <View style={styles.tableServColHeader}>
          <Text style={styles.tableCellHeader} render={() => `${p}`}></Text>
        </View>
      ));

    const PeopleServedSummaryTable = () =>
      this.state.peopleServedSummaryData.map((d) => (
        <View style={styles.tableRow}>
          {this.state.peopleServedSummaryHeaders.map((h) => (
            <View style={styles.tableServCol}>
              <Text style={styles.tableCell} render={() => `${d[h]}`}></Text>
            </View>
          ))}
        </View>
      ));

    return (
      <Container fluid="xl">
        <Form>
          <Row form>
            <Col>
              <FormGroup>
                <Label for="report">Report Selection</Label>
                <Input
                  type="select"
                  name="report"
                  id="report"
                  placeholder=""
                  value={this.state.report}
                  onChange={this.handleReportChange}
                >
                  <option>rpt_disability</option>
                  <option>rpt_limited_english_proficiency</option>
                  <option>rpt_veteran</option>
                  <option>rpt_senior</option>
                  <option>rpt_information_referral</option>
                  <option>rpt_financial</option>
                  <option>rpt_food</option>
                  <option>rpt_invest_in_yourself</option>
                  <option>rpt_income_report</option>
                  <option>rpt_first_time_visitors</option>
                  <option>rpt_unduplicated</option>
                  <option>rpt_minor_by_program</option>
                  <option>rpt_minor_by_description</option>
                  <option>rpt_senior_by_program</option>
                  <option>rpt_senior_by_description</option>
                  <option>weekly_food_pantry_visits</option>


                </Input>
              </FormGroup>
            </Col>
            {this.state.report === "rpt_unduplicated" && (
              <Col>
                <FormGroup>
                  <Label for="program">Program</Label>
                  <Input
                    type="select"
                    name="program"
                    id="program"
                    placeholder=""
                    value={this.state.program}
                    onChange={this.handleChange}
                  >
                    <option>All</option>
                    <option>Emergency Assistance</option>
                    <option>Financial</option>
                    <option>Food</option>
                    <option>Information and Referral</option>
                    <option>Invest In Yourself</option>
                    <option>Rent</option>
                  </Input>
                </FormGroup>
              </Col>
            )}
            <Col>
              <FormGroup>
                <Label>Start Date</Label>
                <NumberFormat
                  type="text"
                  name="startDate"
                  id="startDate"
                  placeholder=""
                  onChange={this.handleDateChange}
                  format="##/##/####"
                  mask="_"
                  customInput={Input}
                  invalid={!this.state.validateStartDate}
                />
                <FormFeedback invalid>Please Check the Date!</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>End Date</Label>
                <NumberFormat
                  type="text"
                  name="endDate"
                  id="endDate"
                  placeholder=""
                  onChange={this.handleDateChange}
                  format="##/##/####"
                  mask="_"
                  customInput={Input}
                  invalid={!this.state.validateEndDate}
                />
                <FormFeedback invalid>Please Check the Date!</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Fiscal Year Start Date</Label>
                <NumberFormat
                  type="text"
                  name="ytdStartDate"
                  id="ytdStartDate"
                  placeholder=""
                  onChange={this.handleDateChange}
                  format="##/##/####"
                  mask="_"
                  customInput={Input}
                  invalid={!this.state.validateYtdStartDate}
                />
                <FormFeedback invalid>Please Check the Date!</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <br />
              <Button
                onClick={() => (
                  this.loadReportData(this.state.report),
                  this.loadClientSummaryReportData(this.state.report),
                  this.loadDescriptionSummaryReportData(this.state.report),
                  this.loadFoodPeopleServedSummary(this.state.report)
                )}
              >
                {this.state.loading && <Spinner></Spinner>}
                {!this.state.loading && "Load Data"}
              </Button>
            </Col>{" "}
          </Row>
        </Form>
        {this.state.missingData && <Col>Parameters returned No Data</Col>}
        {this.state.showPDF &&
          Moment(this.state.startDate).isValid() &&
          Moment(this.state.endDate).isValid() && (
            <PDFViewer
              style={{
                textDecoration: "none",
                padding: "10px",
                color: "#4a4a4a",
                backgroundColor: "#f2f2f2",
                border: "1px solid #4a4a4a",
                width: "100%",
                height: "1000px",
              }}
            >
              {" "}
              <Document>
                <Page style={styles.body}>
                  <View>
                    <Text
                      render={() =>
                        `${reportTitle} \n Reporting Period - ${this.state.startDate} through ${this.state.endDate} with Fiscal Year start of ${this.state.ytdStartDate}`.replace(
                          /'/g,
                          ""
                        )
                      }
                      style={styles.reportTitle}
                    ></Text>
                  </View>
                  <View style={styles.table}>
                    <Text style={styles.tableTitle}>Client Summary Table</Text>
                    <View style={styles.tableRow} fixed>
                      <ClientSummaryHeader />
                    </View>
                    <ClientSummaryTable />
                  </View>
                  {showDescriptionSummary === 1 && (
                    <View style={styles.table}>
                      <Text style={styles.tableTitle}>
                        Description Summary Table
                      </Text>
                      <View style={styles.tableRow} fixed>
                        <DescriptionSummaryHeader />
                      </View>
                      <DescriptionSummaryTable />
                    </View>
                  )}
                  {showPeopleServedSummary === 1 && (
                    <View style={styles.table}>
                      <Text style={styles.tableTitle}>
                        People Served Summary Table
                      </Text>
                      <View style={styles.tableRow} fixed>
                        <PeopleServedSummaryHeader />
                      </View>
                      <PeopleServedSummaryTable />
                    </View>
                  )}
                  <Text
                    style={styles.pageNumber}
                    render={({ pageNumber, totalPages }) =>
                      `${reportTitle} \n Reporting Period - ${this.state.startDate} through ${this.state.endDate} \n ${pageNumber} / ${totalPages}`
                    }
                    fixed
                  />
                </Page>
              </Document>
            </PDFViewer>
          )}
        {this.state.showTable &&
          Moment(this.state.startDate).isValid() &&
          Moment(this.state.endDate).isValid() &&
          Moment(this.state.ytdStartDate).isValid() && (
            <ReactTable
              data={this.state.data}
              defaultPageSize={defaultPageSize}
              columns={[
                {
                  Header: "Details Table",
                  columns: columns,
                },
              ]}
              className="details -striped"
              getTrProps={this.getTrProps}
            />
          )}
        {this.state.showLink &&
          Moment(this.state.startDate).isValid() &&
          Moment(this.state.endDate).isValid() &&
          Moment(this.state.ytdStartDate).isValid() && (
            <Col>
              <CSVLink
                data={this.state.data}
                filename={this.state.report + ".csv"}
              >
                CSV Download Link
              </CSVLink>
            </Col>
          )}
      </Container>
    );
  }
}

export default ReportTest;
