import React, { Component } from "react";
import {
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Row,
  Col,
} from "reactstrap";
import "react-toggle/style.css";
import NumberFormat from "react-number-format";

class UpdatePersonalIDForm extends Component {
  static defaultProps = {
    person: {},
  };

  state = {
    person_id: this.props.person.id,
    personal_id: this.props.person.personal_id,
    personal_id_desc: this.props.person.personal_id_desc,
    head_of_household: this.props.person.head_of_household,
    household_id: this.props.person.household_id,
    personal_phone: this.props.person.home_phone,
  };

  handleChange = (event) => {
    const target = event.target.id;
    const value = event.target.value;
    this.setState({ [target]: value });
  };

  handleChangeOther = (event) => {
    const value = event.target.value;
    this.setState({ personal_id_desc: value });
  };

  componentDidMount() {
    console.log(this.props.person);
  }

  render() {
    return (
      <Container>
        <Form>
          <Row>
            <Col>
              <FormGroup>
                <Label for="personal_id">Personal ID</Label>
                <NumberFormat
                  type="text"
                  name="personal_id"
                  id="personal_id"
                  placeholder="Unknown"
                  value={this.state.personal_id}
                  onChange={this.handleChange}
                  customInput={Input}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="personal_id_desc">Personal ID Desc</Label>
                <Input
                  type="select"
                  name="personal_id_desc"
                  id="personal_id_desc"
                  value={this.state.personal_id_desc}
                  onChange={this.handleChange}
                >
                  {["TXID", "TXDL"].indexOf(this.state.personal_id_desc) >
                  -1 ? (
                    <>
                      <option>TXDL</option>
                      <option>TXID</option>
                      <option>Other</option>
                    </>
                  ) : (
                    <>
                      <option>Other</option>
                      <option>TXDL</option>
                      <option>TXID</option>
                    </>
                  )}
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="personal_id_desc">Enter Other Value:</Label>
                {this.state.personal_id_desc !== "TXDL" ? (
                  <Input
                    type="text"
                    name="personal_id_desc_other"
                    id="personal_id_desc_other"
                    value={this.state.personal_id_desc}
                    onChange={this.handleChangeOther}
                  ></Input>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Button
            onClick={(e) => {
              e.preventDefault();
              this.props.savePersonalID(this.state.person_id, this.state);
              console.log(this.state);
            }}
          >
            Save
          </Button>
        </Form>
      </Container>
    );
  }
}

export default UpdatePersonalIDForm;
