import React, { Component } from "react";
import {
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import "react-toggle/style.css";
import api from "../utils/api";
import NumberFormat from "react-number-format";
import Select from "react-select";
import ProgramOptions from "../utils/program-options";
import DescriptionOptions from "../utils/description-options";
import CaseworkerOptions from "../utils/caseworker-options";

class VisitForm extends Component {
  static defaultProps = {
    visit: {},
  };

  state = {
    name: this.props.visit.name,
    visit_date: this.props.visit.visit_date || new Date(),
    program: this.props.visit.program,
    description: this.props.visit.description || "",
    case_worker: this.props.visit.case_worker,
    case_worker_hours: this.props.visit.case_worker_hours || 0,
    dollar_amount:
      this.props.visit.dollar_amount.replace("$", "").replace(",", "") || 0,
    units: this.props.visit.units || 0,
    narrative: this.props.visit.narrative,
    days: this.props.visit.days || 0,
    selectedProgram: this.props.visit.program || "",
    currentUser: api.logged_in_user(),
  };

  componentDidMount() {
    console.log(this.state.currentUser);
  }

  handleChange = (event) => {
    var updateFields = ["dollar_amount", "units", "days", "case_worker_hours"];
    const target = event.target.id;
    const value =
      event.target.value === "" && updateFields.includes(event.target.id)
        ? 0
        : event.target.value;
    this.setState({ [target]: value });
  };

  handleDollarAmountChange = (values) => {
    const value = values.value === "" ? 0 : values.value;
    this.setState({ dollar_amount: value });
  };

  handleDropdownProgramChange = (selectedOption) => {
    this.setState({
      program: selectedOption.value,
      description:
        this.state.description === "" && selectedOption.value === "Food"
          ? "Food"
          : this.state.description,
    });
    this.setDays(selectedOption.value);
  };

  handleDropdownDescriptionChange = (selectedOption) => {
    this.setState({
      description: selectedOption.value,
    });
  };

  handleCaseWorkerChange = (selectedOption) => {
    this.setState({
      case_worker: selectedOption.value,
    });
  };

  setDays = (program) => {
    this.setState({ days: program === "Food" ? 7 : 0 });
  };

  render() {
    const progOptions = ProgramOptions;

    const descOptions = DescriptionOptions;

    const cwOptions = CaseworkerOptions;

    const filteredDescOptions = descOptions.filter(
      (o) => o.link === this.state.program
    );

    const defaultProgram = progOptions.filter(
      (o) => o.value === this.state.program
    );

    const defaultCaseworker = cwOptions.filter(
      (o) => o.value === this.state.case_worker
    );

    const defaultProgramDescription =
      this.state.description === "" && this.state.program === "Food"
        ? "Food"
        : this.state.description;

    console.log(this.state.program);

    const defaultDescription = filteredDescOptions.filter(
      (o) => o.value === this.state.description
    );

    console.log(defaultDescription);
    console.log(this.state.description);
    return (
      <Container>
        <Form>
          <Row form>
            <Col>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder=""
                  value={this.state.name}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="visit_date">Visit Date</Label>
                <NumberFormat
                  type="text"
                  name="visit_date"
                  id="visit_date"
                  value={this.state.visit_date.toString()}
                  placeholder="9/21/1980"
                  onChange={this.handleChange}
                  customInput={Input}
                  format="##/##/####"
                  mask="_"
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Case Manager</Label>
                <Select
                  type="text"
                  name="case_worker"
                  id="case_worker"
                  isSearchable={true}
                  onChange={this.handleCaseWorkerChange}
                  defaultValue={defaultCaseworker}
                  options={CaseworkerOptions}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="program">Program</Label>
                <Select
                  name="program"
                  id="program"
                  isSearchable={true}
                  defaultValue={defaultProgram}
                  onChange={this.handleDropdownProgramChange}
                  options={progOptions}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="description">Description</Label>
                <Select
                  name="description"
                  id="description"
                  isSearchable={true}
                  value={defaultDescription}
                  onChange={this.handleDropdownDescriptionChange}
                  options={filteredDescOptions}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="dollar_amount">Financial Assistance</Label>
                <NumberFormat
                  type="text"
                  name="dollar_amount"
                  id="dollar_amount"
                  onValueChange={this.handleDollarAmountChange}
                  value={this.state.dollar_amount}
                  thousandSeparator={true}
                  displayType="input"
                  prefix={"$"}
                  customInput={Input}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="case_worker_hours">Hours</Label>
                <Input
                  type="text"
                  name="case_worker_hours"
                  id="case_worker_hours"
                  onChange={this.handleChange}
                  value={this.state.case_worker_hours}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="units">Units</Label>
                <Input
                  type="text"
                  name="units"
                  id="units"
                  onChange={this.handleChange}
                  value={this.state.units}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="days">Days</Label>
                <Input
                  type="text"
                  name="days"
                  id="days"
                  onChange={this.handleChange}
                  value={this.state.days}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            {" "}
            <Col>
              <FormGroup>
                <Label for="narrative">Narrative</Label>
                <Input
                  type="textarea"
                  rows="4"
                  name="narrative"
                  id="narrative"
                  onChange={this.handleChange}
                  value={this.state.narrative}
                />
              </FormGroup>
            </Col>
          </Row>
          <Button
            onClick={(e) => {
              e.preventDefault();
              this.props.saveVisit(this.props.visit.id, this.state);
            }}
          >
            Save
          </Button>
        </Form>
      </Container>
    );
  }
}

export default VisitForm;
