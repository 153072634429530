import React, { Component } from "react";
import {
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Row,
  Col,
} from "reactstrap";
import "react-toggle/style.css";

class UpdateAddressForm extends Component {
  static defaultProps = {
    household: {},
  };

  state = {
    household_id: this.props.household.household_id,
    address1: this.props.household.address1 || "Unknown",
    apt_no: this.props.household.apt_no || "Unknown",
    city: this.props.household.city || "Unknown",
    state: this.props.household.state || "Unknown",
    zip_code: this.props.household.zip_code || "Unknown",
  };

  handleChange = (event) => {
    const target = event.target.id;
    const value = event.target.value;
    this.setState({ [target]: value });
  };

  render() {
    return (
      <Container>
        <Form>
          <Row>
            <Col>
              <FormGroup>
                <Label for="address1">Address</Label>
                <Input
                  type="text"
                  name="address1"
                  id="address1"
                  placeholder="Unknown"
                  value={this.state.address1}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="apt_no">Apt No.</Label>
                <Input
                  type="text"
                  name="apt_no"
                  id="apt_no"
                  placeholder="Unknown"
                  value={this.state.apt_no}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="city">City</Label>
                <Input
                  type="select"
                  name="city"
                  id="city"
                  placeholder="Unknown"
                  value={this.state.city}
                  onChange={this.handleChange}
                >
                  {" "}
                  <option>Unknown</option>
                  <option>Irving</option>
                  <option>Other</option>
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="state">State</Label>
                <Input
                  type="select"
                  name="state"
                  id="state"
                  placeholder="Unknown"
                  value={this.state.state}
                  onChange={this.handleChange}
                >
                  <option>Unknown</option>
                  <option>TX</option>
                  <option>Other</option>
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="zip_code">Zip Code</Label>
                <Input
                  type="select"
                  name="zip_code"
                  id="zip_code"
                  placeholder="Unknown"
                  value={this.state.zip_code}
                  onChange={this.handleChange}
                >
                  <option>Unknown</option>
                  <option>75014</option>
                  <option>75015</option>
                  <option>75016</option>
                  <option>75017</option>
                  <option>75038</option>
                  <option>75039</option>
                  <option>75060</option>
                  <option>75061</option>
                  <option>75062</option>
                  <option>75063</option>
                  <option>75064</option>
                  <option>HOMELESS</option>
                  <option>BT</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Button
            onClick={(e) => {
              e.preventDefault();
              this.props.saveHousehold(this.state.household_id, this.state);
            }}
          >
            Save
          </Button>
        </Form>
      </Container>
    );
  }
}

export default UpdateAddressForm;
