const options = [
  { value: "Food", label: "Food" },
  { value: "Pantry/Other", label: "Pantry/Other" },
  { value: "Financial", label: "Financial" },
  { value: "Information and Referral", label: "Information and Referral" },
  { value: "Invest In Yourself", label: "Invest In Yourself" },
  { value: "Employment Services", label: "Employment Services" },
  { value: "Education", label: "Education" },

];

export default options;
