import React, { Component } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-table/react-table.css";
import Main from "./components/main";

class App extends Component {
  render() {
    return <Main />;
  }
}

export default App;
