import React, { Component } from "react";
import {
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
  Button
} from "reactstrap";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "react-toggle/style.css";
import NumberFormat from "react-number-format";

class PhoneForm extends Component {
  static defaultProps = {
    household: {}
  };

  state = {
    allow_input: null,
    home_phone: this.props.household.home_phone
  };

  handleChange = event => {
    const target = event.target.id;
    const value = event.target.value;
    this.setState({ [target]: value });
  };

  render() {
    return (
      <Container>
        <Form>
          <Row form>
            <Col>
              <FormGroup>
                <NumberFormat
                  type="text"
                  name="home_phone"
                  id="home_phone"
                  placeholder="(555) 555-5555"
                  value={this.state.home_phone}
                  onChange={this.handleChange}
                  format="(###) ###-####"
                  mask="_"
                  customInput={Input}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              {" "}
              <Button
                onClick={e => {
                  e.preventDefault();
                  this.props.savePhone(
                    this.props.household.household_id,
                    this.state.home_phone
                  );
                }}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    );
  }
}

export default PhoneForm;

const CustomInput = ({ date, ...others }) => (
  <Input {...others} value={moment(date).format("MM/DD/YYYY")} />
);
