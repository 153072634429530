import React, { Component } from "react";
import {
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Row,
  Col,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import NumberFormat from "react-number-format";

class PersonForm extends Component {
  static defaultProps = {
    person: {},
  };

  state = {
    first_name: this.props.person.first_name,
    middle_name: this.props.person.middle_name,
    last_name: this.props.person.last_name,
    personal_id: this.props.person.personal_id,
    personal_id_desc: this.props.person.personal_id_desc,
    email: this.props.person.email,
    personal_phone: this.props.person.personal_phone,
    marital_status: this.props.person.marital_status,
    race: this.props.person.race,
    ethnicity: this.props.person.ethnicity,
    gender: this.props.person.gender || "Male",
    limited_english: this.props.person.limited_english || 0,
    veteran: this.props.person.veteran || 0,
    notes: this.props.person.notes,
    social_security_number: this.props.person.social_security_number,
    dob: this.props.person.dob || "",
    relationship: this.props.person.relationship,
    proof_of_residency: this.props.person.proof_of_residency,
    age: this.props.person.age,
    head_of_household: this.props.person.head_of_household,
    household_id: this.props.person.household_id,
  };

  handleChange = (event) => {
    const target = event.target.id;
    const value = event.target.value;
    this.setState({ [target]: value });
  };

  handleDateChange = (date) => {
    this.setState({
      dob: date,
    });
  };

  handleToggleChange = () => {
    this.setState({
      limited_english: this.state.limited_english === 1 ? 0 : 1,
    });
  };

  handleChangeOther = (event) => {
    const value = event.target.value;
    this.setState({ relationship: value });
  };

  handleChangeOtherID = (event) => {
    const value = event.target.value;
    this.setState({ personal_id_desc: value });
  };

  render() {
    return (
      <Container>
        <Form>
          <Row>
            <Col>
              <FormGroup>
                <Label for="first_name">First Name</Label>
                <Input
                  type="text"
                  name="first_name"
                  id="first_name"
                  placeholder="First Name"
                  value={this.state.first_name}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="middle_name">Middle Name</Label>
                <Input
                  type="text"
                  name="middle_name"
                  id="middle_name"
                  placeholder="Middle Name"
                  onChange={this.handleChange}
                  value={this.state.middle_name}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="last_name">Last Name</Label>
                <Input
                  type="text"
                  name="last_name"
                  id="last_name"
                  placeholder="Last Name"
                  onChange={this.handleChange}
                  value={this.state.last_name}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="dob">Birthdate</Label>
                <NumberFormat
                  type="text"
                  name="dob"
                  id="dob"
                  value={this.state.dob.toString()}
                  placeholder="01/01/1900"
                  onChange={this.handleChange}
                  customInput={Input}
                  format="##/##/####"
                  mask="_"
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="personal_phone">Phone Number</Label>
                <NumberFormat
                  type="text"
                  name="personal_phone"
                  id="personal_phone"
                  placeholder="(555) 555-5555"
                  value={this.state.personal_phone}
                  onChange={this.handleChange}
                  format="(###) ###-####"
                  mask="_"
                  customInput={Input}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="state">Proof of Residency</Label>
                <Input
                  type="select"
                  name="state"
                  id="proof_of_residency"
                  placeholder="Unknown"
                  value={this.state.proof_of_residency}
                  onChange={this.handleChange}
                >
                  <option>Unknown</option>
                  <option>Utility Bill</option>
                  <option>TX Drivers Licence</option>
                  <option>Texas ID</option>
                  <option>Brighter Tomorrows</option>
                  <option>Letter</option>
                  <option>Lease</option>
                  <option>ID - Other</option>
                  <option>Homeless</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="social_security_number">SSN</Label>
                <NumberFormat
                  type="text"
                  name="social_security_number"
                  id="social_security_number"
                  placeholder="555-55-5555"
                  value={this.state.social_security_number}
                  onChange={this.handleChange}
                  format="###-##-####"
                  mask="_"
                  customInput={Input}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="personal_id">Personal ID</Label>
                <NumberFormat
                  type="text"
                  name="personal_id"
                  id="personal_id"
                  placeholder="Unknown"
                  value={this.state.personal_id}
                  onChange={this.handleChange}
                  customInput={Input}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="personal_id_desc">Personal ID Desc</Label>
                <Input
                  type="select"
                  name="personal_id_desc"
                  id="personal_id_desc"
                  value={this.state.personal_id_desc}
                  onChange={this.handleChange}
                >
                  {["TXID", "TXDL"].indexOf(this.state.personal_id_desc) >
                  -1 ? (
                    <>
                      <option>TXDL</option>
                      <option>TXID</option>
                      <option>Other</option>
                    </>
                  ) : (
                    <>
                      <option>Other</option>
                      <option>TXDL</option>
                      <option>TXID</option>
                    </>
                  )}
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="personal_id_desc">Enter Other Value:</Label>
                {this.state.personal_id_desc !== "TXDL" ? (
                  <Input
                    type="text"
                    name="personal_id_desc_other"
                    id="personal_id_desc_other"
                    value={this.state.personal_id_desc}
                    onChange={this.handleChangeOther}
                  ></Input>
                ) : null}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="someone@email.com"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="relationship">Relationship</Label>
                <Input
                  type="select"
                  name="relationship"
                  id="relationship"
                  value={this.state.relationship}
                  onChange={this.handleChange}
                >
                  <option>Spouse</option>
                  <option>Child</option>
                  <option>Grandparent</option>
                  <option>Grandchild</option>
                  <option>Friend</option>
                  <option>Aunt/Uncle</option>
                  <option>Fiancee/Fiance</option>
                  <option>Girlfriend/Boyfriend</option>
                  <option>Parent</option>
                  <option>Sibling</option>
                  <option>Niece/Nephew</option>
                  <option>Head of Household</option>
                  <option>Other</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="marital_status">Marital status</Label>
                <Input
                  type="select"
                  name="marital_status"
                  id="marital_status"
                  placeholder=""
                  value={this.state.marital_status}
                  onChange={this.handleChange}
                >
                  <option>Unknown</option>
                  <option>Single</option>
                  <option>Married</option>
                  <option>Divorced</option>
                  <option>Separated</option>
                  <option>Cohabitating</option>
                  <option>Widowed</option>
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="gender">Gender</Label>
                <Input
                  type="select"
                  name="gender"
                  id="gender"
                  value={this.state.gender}
                  onChange={this.handleChange}
                >
                  <option>Male</option>
                  <option>Female</option>
                  <option>Other</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="race">Race</Label>
                <Input
                  type="select"
                  name="race"
                  id="race"
                  placeholder=""
                  value={this.state.race}
                  onChange={this.handleChange}
                >
                  <option>Unknown</option>
                  <option>American Indian/Alaska Native</option>
                  <option>Asian</option>
                  <option>Black/African American</option>
                  <option>White</option>
                  <option>Native Hawaiian/Pacific Island</option>
                  <option>Latino</option>
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="ethnicity">Ethnicity</Label>
                <Input
                  type="select"
                  name="ethnicity"
                  id="ethnicity"
                  placeholder=""
                  value={this.state.ethnicity}
                  onChange={this.handleChange}
                >
                  <option>Unknown</option>
                  <option>Hispanic/Latino</option>
                  <option>non-Hispanic/Non-Latino</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Toggle
                  id="limited_english"
                  defaultChecked={this.state.limited_english}
                  name="limited_english"
                  value="1"
                  onChange={() =>
                    this.setState({
                      limited_english: this.state.limited_english === 1 ? 0 : 1,
                    })
                  }
                />
                <Label for="limited_english">Limited English</Label>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Toggle
                  id="head_of_household"
                  defaultChecked={this.state.head_of_household}
                  name="head_of_household"
                  onChange={() =>
                    this.setState({
                      head_of_household:
                        this.state.head_of_household === 1 ? 0 : 1,
                    })
                  }
                />
                <Label for="head_of_household">Head of Household</Label>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Toggle
                  id="veteran"
                  defaultChecked={this.state.veteran}
                  name="veteran"
                  value="1"
                  onChange={() =>
                    this.setState({ veteran: this.state.veteran === 1 ? 0 : 1 })
                  }
                />
                <Label for="veteran">Veteran</Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Button
              onClick={(e) => {
                e.preventDefault();
                this.props.savePerson(this.props.person.id, this.state);
              }}
            >
              Save
            </Button>
          </Row>
        </Form>
      </Container>
    );
  }
}

export default PersonForm;
