import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardDeck,
  CardTitle,
  CardText,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import ReactTable from "react-table";
import api from "../utils/api";
import VisitForm from "./modal_visit_form";
import DisabilityForm from "./modal_disability_form";
import IncomeForm from "./modal_household_income_form";
import PersonForm from "./person_form";
import PhoneForm from "./modal_phone";
import AddressForm from "./modal_update_address_form";
import NotesForm from "./modal_notes_form";
import PersonalIDForm from "./modal_update_personal_id_form";
import { CSVLink } from "react-csv";
import Toggle from "react-toggle";
import ReactToPrint from "react-to-print";

class Household extends Component {
  state = {
    details: {},
    data: [],
    loadingData: false,
    redirect: null,
    selectedVisit: {},
    visitFormVisible: false,
    newVisitFormVisible: false,
    disabilityFormVisible: false,
    selectedDisabled: {},
    incomeFormVisible: false,
    selectedIncome: {},
    selectedPerson: {},
    personFormVisible: false,
    phoneFormVisible: false,
    addressFormVisible: false,
    personalIDFormVisible: false,
    notesFormVisible: false,
    selectedNotes: {},
    visitHistoryButtonsToggle: true,
  };

  handle_got_details = (response) => {
    this.setState({ details: response.data });
    this.setState({ shredded: this.state.details.shredded });
    this.setState({ shredded_date: this.state.details.shredded_date });
  };

  handle_visit_details = () => {
    api
      .household_details(this.props.person_id, this.props.household_id)
      .then((response) => {
        this.setState(
          { details: response.data },
          this.showVisit(response.data.visits[0])
        );
      });
  };

  handleHouseholdDetails = () => {
    api
      .household_details(this.props.person_id, this.props.household_id)
      .then((response) => {
        this.setState(
          { details: response.data },
          this.showPersonEdit(response.data.last_member[0])
        );
      });
  };

  componentDidMount() {
    api
      .household_details(this.props.person_id, this.props.household_id)
      .then(this.handle_got_details);
  }

  showVisit = (visit_to_show) => {
    this.setState({ visitFormVisible: true, selectedVisit: visit_to_show });
  };

  toggleVisitForm = () => {
    this.setState((prevState) => ({
      visitFormVisible: !prevState.visitFormVisible,
    }));
  };

  saveVisit = (visit, attributes) => {
    api.updateVisit(visit, attributes).then(() => {
      this.toggleVisitForm();
      api
        .household_details(this.props.person_id, this.props.household_id)
        .then(this.handle_got_details);
    });
  };

  newService = (person) => {
    api.newService(person).then(this.handle_visit_details);
  };

  removeService = (visit) => {
    api
      .removeService(visit.id)
      .then(
        api
          .household_details(this.props.person_id, this.props.household_id)
          .then(this.handle_got_details)
      );
  };

  removeMember = (person) => {
    api
      .removeHHMember(person)
      .then(
        api
          .household_details(this.props.person_id, this.props.household_id)
          .then(this.handle_got_details)
      );
  };

  showDisabled = (disabled_to_show) => {
    this.setState({
      disabilityFormVisible: true,
      selectedDisabled: disabled_to_show,
    });
  };

  showNotes = (notes_to_show) => {
    this.setState({
      notesFormVisible: true,
      selectedNotes: notes_to_show,
    });
  };

  toggleDisabilityForm = () => {
    this.setState((prevState) => ({
      disabilityFormVisible: !prevState.disabilityFormVisible,
    }));
  };

  saveDisabled = (household, attributes) => {
    api.updateDisability(household, attributes).then(() => {
      this.toggleDisabilityForm();
      api
        .household_details(this.props.person_id, this.props.household_id)
        .then(this.handle_got_details);
    });
  };

  showIncome = (income_to_show) => {
    this.setState({
      incomeFormVisible: true,
      selectedIncome: income_to_show,
    });
  };

  toggleIncomeForm = () => {
    this.setState((prevState) => ({
      incomeFormVisible: !prevState.incomeFormVisible,
    }));
  };

  saveIncome = (
    household,
    annual_household_income,
    monthly_disability_income,
    monthly_social_security_income,
    monthly_child_support_income,
    monthly_employment_income
  ) => {
    api
      .updateIncome(
        household,
        annual_household_income,
        monthly_disability_income,
        monthly_social_security_income,
        monthly_child_support_income,
        monthly_employment_income
      )
      .then(() => {
        this.toggleIncomeForm();
        api
          .household_details(this.props.person_id, this.props.household_id)
          .then(this.handle_got_details);
      });
  };

  showPhone = (phone_to_show) => {
    this.setState({
      phoneFormVisible: true,
      selectedPhone: phone_to_show,
    });
  };

  togglePhoneForm = () => {
    this.setState((prevState) => ({
      phoneFormVisible: !prevState.phoneFormVisible,
    }));
  };

  savePhone = (household, home_phone) => {
    console.log(household);
    api.updatePhone(household, home_phone).then(() => {
      this.togglePhoneForm();
      api
        .household_details(this.props.person_id, this.props.household_id)
        .then(this.handle_got_details);
    });
  };

  newHHMember = (household_id) => {
    api
      .newHHMember(household_id)
      .then(
        api
          .household_details(this.props.person_id, this.props.household_id)
          .then(this.handleHouseholdDetails)
      );
  };

  showPersonEdit = (person_to_edit) => {
    this.setState({ personFormVisible: true, selectedPerson: person_to_edit });
  };

  togglePersonForm = () => {
    this.setState((prevState) => ({
      personFormVisible: !prevState.personFormVisible,
    }));
  };

  savePerson = (person, attributes) => {
    api.updatePerson(person, attributes).then(() => {
      this.togglePersonForm();
      api
        .household_details(this.props.person_id, this.props.household_id)
        .then(this.handle_got_details);
    });
  };

  toggleAddressForm = () => {
    this.setState((prevState) => ({
      addressFormVisible: !prevState.addressFormVisible,
    }));
  };

  toggleNotesForm = () => {
    this.setState((prevState) => ({
      notesFormVisible: !prevState.notesFormVisible,
    }));
  };

  showAddress = (address) => {
    this.setState({
      addressFormVisible: true,
      selectedAddress: address,
    });
  };

  togglePersonalIDForm = () => {
    this.setState((prevState) => ({
      personalIDFormVisible: !prevState.personalIDFormVisible,
    }));
  };

  showPersonalID = (personalID) => {
    this.setState({
      personalIDFormVisible: true,
      selectedPersonalID: personalID,
    });
  };

  savePersonalID = (person, attributes) => {
    api.updatePerson(person, attributes).then(() => {
      this.togglePersonalIDForm();
      api
        .household_details(this.props.person_id, this.props.household_id)
        .then(this.handle_got_details);
    });
  };

  saveHousehold = (household_id, attributes) => {
    console.log(attributes);
    api.updateHousehold(household_id, attributes).then(() => {
      this.toggleAddressForm();
      api
        .household_details(this.props.person_id, this.props.household_id)
        .then(this.handle_got_details);
    });
  };

  saveNotes = (household_id, attributes) => {
    api.updateHousehold(household_id, attributes).then(() => {
      this.toggleNotesForm();
      api
        .household_details(this.props.person_id, this.props.household_id)
        .then(this.handle_got_details);
    });
  };

  handleChange = () => {
    this.setState({
      shredded: this.state.shredded === 1 ? 0 : 1,
      shredded_date: this.state.shredded === 1 ? null : new Date(),
    });

    api.updateShredded(
      this.props.household_id,
      this.state.shredded === 1 ? 0 : 1,
      this.state.shredded === 1 ? null : new Date()
    );
    api
      .household_details(this.props.person_id, this.props.household_id)
      .then(this.handle_got_details);
  };

  toggleVisitHistoryColumn = () => {
    this.setState((prevState) => ({
      visitHistoryButtonsToggle: !prevState.visitHistoryButtonsToggle,
    }));
  };

  render() {
    const tableStyle = {
      textAlign: "center",
    };
    const household_table = (
      <ReactTable
        data={this.state.details.members || []}
        showPagination={false}
        NoDataComponent={() => null}
        pageSize={(this.state.details.members || []).length}
        style={tableStyle}
        columns={[
          {
            Header: "Household Members",
            headerStyle: { background: "#B0C4DE", fontSize: 22 },
            columns: [
              {
                Header: "First Name",
                accessor: "first_name",
              },
              {
                Header: "Middle Name",
                accessor: "middle_name",
                show: false,
              },
              {
                Header: "Last Name",
                accessor: "last_name",
              },
              {
                Header: "Date of Birth",
                accessor: "dob",
              },
              {
                Header: "Age",
                accessor: "age",
              },
              {
                Header: "Race",
                accessor: "race",
              },
              {
                Header: "Gender",
                accessor: "gender",
              },
              {
                Header: "Relationship",
                accessor: "relationship",
              },
              {
                Header: "Household Id",
                accessor: "household_id",
                maxWidth: "100",
                show: false,
              },
              {
                Header: "",
                maxWidth: 250,
                Cell: (row) => {
                  return (
                    <>
                      <Button
                        onClick={() => this.showPersonEdit(row.original)}
                        color="primary"
                      >
                        Update
                      </Button>{" "}
                      <Button
                        onClick={() =>
                          window.confirm(
                            "Are you sure you wish to remove this person from the Household?"
                          ) && this.removeMember(row.original.id)
                        }
                        color="danger"
                      >
                        Remove
                      </Button>
                    </>
                  );
                },
              },
            ],
          },
        ]}
        className="details -striped"
      />
    );
    const visit_table = (
      <ReactTable
        data={this.state.details.visits || []}
        SubComponent={(row) => {
          return (
            <div style={{ backgroundColor: "lightgrey" }}>
              {row.original.narrative}
            </div>
          );
        }}
        showPagination={true}
        defaultPageSize={5}
        style={tableStyle}
        striped
        ref={(el) => (this.componentRef = el)}
        columns={[
          {
            Header: "Visit History (SOAP)",
            headerStyle: { background: "#B0C4DE", fontSize: 22 },
            columns: [
              {
                Header: "Name",
                accessor: "name",
                maxWidth: 150,
                style: { whiteSpace: "unset", textAlign: "center" },
              },
              {
                Header: "Visit Date",
                accessor: "visit_date",
                maxWidth: 125,
              },
              {
                Header: "Case Manager",
                accessor: "case_worker",
                maxWidth: 125,
                style: { whiteSpace: "unset", textAlign: "center" },
              },
              {
                Header: "Program",
                accessor: "program",
                maxWidth: 100,

                style: { whiteSpace: "unset", textAlign: "center" },
              },
              {
                Header: "Description",
                accessor: "description",
                style: { whiteSpace: "unset", textAlign: "center" },
                maxWidth: 150,
              },
              {
                Header: "Financial Assistance",
                accessor: "dollar_amount",
                maxWidth: 150,
              },
              {
                Header: "Hours",
                accessor: "case_worker_hours",
                maxWidth: 75,
              },
              {
                Header: "Units",
                accessor: "units",
                maxWidth: 75,
              },
              {
                Header: "Days",
                accessor: "days",
                maxWidth: 75,
              },
              {
                Header: "Updated By",
                accessor: "updated_by",
                maxWidth: 100,
                style: { whiteSpace: "unset", textAlign: "center" },
              },
              {
                Header: "Updated Date",
                accessor: "updated_date",
                maxWidth: 125,
              },
              {
                Header: "",
                style: { whiteSpace: "unset", textAlign: "left" },
                show: this.state.visitHistoryButtonsToggle,
                Cell: (row) => {
                  return (
                    <>
                      <Button
                        onClick={() => this.showVisit(row.original)}
                        color="primary"
                      >
                        Update
                      </Button>{" "}
                      <Button
                        onClick={() =>
                          window.confirm(
                            "Are you sure you wish to remove this service from the Visit History?"
                          ) && this.removeService(row.original)
                        }
                        color="danger"
                      >
                        Remove
                      </Button>
                    </>
                  );
                },
              },
            ],
          },
        ]}
        className="details -striped"
      />
    );

    const cardStyle = {
      textAlign: "center",
    };

    return (
      <div>
        <CardDeck>
          <Mycard
            title="Household Id"
            value={this.state.details.household_id}
          />
          <Mycard title="Last Visit" value={this.state.details.last_visit} />
          <Mycard
            title="Food Pantry Visits"
            value={this.state.details.food_panty_visit_count}
          />
          <Card>
            {" "}
            <CardBody style={{ textAlign: "center" }}>
              <CardTitle>Shredded</CardTitle>
              <Toggle
                id="Shredded"
                checked={this.state.shredded === 1 ? true : false}
                name="Shredded"
                onChange={this.handleChange}
              />
            </CardBody>
          </Card>
          <Card>
            {" "}
            <CardBody style={{ textAlign: "center" }}>
              <CardTitle>Shredded Date</CardTitle>
              <CardText id="shredded_date" name="shredded_date">
                {this.state.details.shredded_date}
              </CardText>
            </CardBody>
          </Card>
        </CardDeck>
        <CardDeck>
          <Card>
            <CardBody style={{ textAlign: "center" }}>
              <CardTitle>Identification</CardTitle>
              <CardText>
                {this.state.details.personal_id}
                <br />
                {this.state.details.personal_id_desc}
              </CardText>
              <Button
                onClick={() => this.showPersonalID(this.state.details)}
                color="primary"
              >
                Update
              </Button>
            </CardBody>
          </Card>
          <Card>
            <CardBody style={cardStyle}>
              <CardTitle>Head of Household Phone</CardTitle>
              <CardText>{this.state.details.home_phone}</CardText>
              <br />
              <Button
                onClick={() => this.showPhone(this.state.details)}
                color="primary"
              >
                Update
              </Button>
            </CardBody>
          </Card>
          <Card>
            <CardBody style={cardStyle}>
              <CardTitle>Address</CardTitle>
              <CardText>
                {this.state.details.address}
                <br />
                {this.state.details.city_state_zip}
              </CardText>
              <Button
                onClick={() => this.showAddress(this.state.details)}
                color="primary"
              >
                Update
              </Button>
            </CardBody>
          </Card>
          <Card>
            <CardBody style={cardStyle}>
              <CardTitle>Disabled</CardTitle>
              <CardText>{this.state.details.disabled_count}</CardText>
              <br />
              <Button
                onClick={() => this.showDisabled(this.state.details)}
                color="primary"
              >
                Update
              </Button>
            </CardBody>
          </Card>
          <Card>
            <CardBody style={cardStyle}>
              <CardTitle>Income</CardTitle>
              <CardText>
                {this.state.details.annual_household_income_display}
              </CardText>
              <br />
              <Button
                onClick={() => this.showIncome(this.state.details)}
                color="primary"
              >
                Update
              </Button>
            </CardBody>
          </Card>
        </CardDeck>
        <Row>
          <Col>
            <Button
              onClick={() => this.newHHMember(this.state.details.household_id)}
              color="success"
            >
              New Household Member
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>{household_table}</Col>
        </Row>
        <Row>
          <Col>
            {" "}
            <Button
              onClick={() => this.showNotes(this.state.details)}
              color="success"
            >
              Update Notes
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            {" "}
            <Card>
              <CardBody style={cardStyle}>
                <CardTitle>Notes</CardTitle>
                <CardText>{this.state.details.notes}</CardText>
                <br />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row style={{ display: "flex", alignItems: "center" }}>
          <Col xs="1">
            <p> </p>
            <Button
              onClick={() => {
                this.newService(this.state.details.id);
              }}
              color="success"
            >
              Add Service
            </Button>
          </Col>{" "}
          <Col xs="1.5">
            <p></p>
            <ReactToPrint
              trigger={() => <a href="#">Print Visit History Out</a>}
              content={() => this.componentRef}
            />
          </Col>
          <Col xs="2">
            <p></p>
            <CSVLink data={this.state.details.visits || []} filename="test.csv">
              Download All Visit History in CSV
            </CSVLink>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <p></p>
            <Toggle
              id="historyButtonToggle"
              checked={
                this.state.visitHistoryButtonsToggle === true ? true : false
              }
              name="historyButtonToggle"
              onChange={this.toggleVisitHistoryColumn}
            />
            <p style={{ textAlign: "right" }}>Toggle History Buttons</p>
          </Col>
        </Row>
        <Row>
          <Col>{visit_table}</Col>
        </Row>
        <Modal
          isOpen={this.state.visitFormVisible}
          toggle={this.toggleVisitForm}
          size="lg"
        >
          <ModalHeader toggle={this.toggleVisitForm} closeButton>
            Service Details
          </ModalHeader>
          <ModalBody>
            <VisitForm
              visit={this.state.selectedVisit}
              saveVisit={this.saveVisit}
            />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.disabilityFormVisible}
          toggle={this.toggleDisabilityForm}
          size="lg"
        >
          {" "}
          <ModalHeader toggle={this.toggleDisabilityForm}>
            Update Disabled Status
          </ModalHeader>
          <ModalBody>
            <DisabilityForm
              household={this.state.selectedDisabled}
              saveDisabled={this.saveDisabled}
            />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.incomeFormVisible}
          toggle={this.toggleIncomeForm}
          size="sm"
        >
          {" "}
          <ModalHeader toggle={this.toggleIncomeForm}>
            Update Income Distribution
          </ModalHeader>
          <ModalBody>
            <IncomeForm
              household={this.state.selectedIncome}
              saveIncome={this.saveIncome}
            />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.phoneFormVisible}
          toggle={this.togglephoneForm}
          size="sm"
        >
          {" "}
          <ModalHeader toggle={this.togglePhoneForm}>
            Update Household Phone
          </ModalHeader>
          <ModalBody>
            <PhoneForm
              household={this.state.selectedPhone}
              savePhone={this.savePhone}
            />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.personFormVisible}
          toggle={this.togglePersonForm}
          size="lg"
        >
          <ModalHeader toggle={this.togglePersonForm}>
            Update Person
          </ModalHeader>
          <ModalBody>
            <PersonForm
              person={this.state.selectedPerson}
              savePerson={this.savePerson}
            />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.addressFormVisible}
          toggle={this.toggleAddressForm}
          size="lg"
        >
          <ModalHeader toggle={this.toggleAddressForm}>
            Update Address
          </ModalHeader>
          <ModalBody>
            <AddressForm
              household={this.state.selectedAddress}
              saveHousehold={this.saveHousehold}
            />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.notesFormVisible}
          toggle={this.toggleNotesForm}
          size="lg"
        >
          <ModalHeader>Update Notes</ModalHeader>
          <ModalBody>
            <NotesForm
              household={this.state.selectedNotes}
              saveHousehold={this.saveNotes}
            />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.personalIDFormVisible}
          toggle={this.togglePersonalIDForm}
          size="lg"
        >
          <ModalHeader toggle={this.togglePersonalIDForm}>
            Update Personal ID
          </ModalHeader>
          <ModalBody>
            <PersonalIDForm
              person={this.state.selectedPersonalID}
              savePersonalID={this.savePersonalID}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default Household;

function Mycard({ title, value }) {
  return (
    <Card>
      <CardBody style={{ textAlign: "center" }}>
        <CardTitle>{title}</CardTitle>
        <CardText>{value}</CardText>
      </CardBody>
    </Card>
  );
}
