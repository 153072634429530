import React, { Component } from "react";
import { Redirect } from "react-router";
import ReactTable from "react-table";
import api from "../utils/api";
import UserForm from "./modal_user_form";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

class Users extends Component {
  state = {
    data: [],
    loadingData: false,
    redirect: null,
    userFormVisible: false,
    selectedUser: ""
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState({ loadingData: true });
    api.query("users").then(result => {
      this.setState({ data: result.data, loadingData: false });
    });
  };

  showUserEdit = user => {
    this.setState({ userFormVisible: true, selectedUser: user });
  };

  toggleUserForm = () => {
    this.setState(prevState => ({
      userFormVisible: !prevState.userFormVisible
    }));
  };

  saveUser = (user, active, role) => {
    api.updateUser(user, active, role).then(() => {
      this.toggleUserForm();
      this.loadData();
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect push to={`/${this.state.redirect}`} />;
    }
    return (
      <div>
        <ReactTable
          style={{ textAlign: "center" }}
          data={this.state.data}
          loading={this.state.loadingData}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          defaultPageSize={25}
          columns={[
            {
              Header: "Search Table",
              columns: [
                {
                  Header: "User",
                  accessor: "user"
                },
                {
                  Header: "Active",
                  accessor: "active"
                },
                {
                  Header: "Role",
                  accessor: "role"
                },
                {
                  Header: "Actions",
                  Cell: row => {
                    return (
                      <>
                        <Button
                          onClick={() => {
                            this.showUserEdit(row.original);
                          }}
                          color="primary"
                        >
                          Update
                        </Button>{" "}
                      </>
                    );
                  }
                }
              ]
            }
          ]}
          className="details -striped -highlight"
        />
        <Modal
          isOpen={this.state.userFormVisible}
          toggle={this.toggleUserForm}
          size="lg"
        >
          <ModalHeader closeButton>User Details</ModalHeader>
          <ModalBody>
            <UserForm user={this.state.selectedUser} saveUser={this.saveUser} />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default Users;
