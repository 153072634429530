import React, { Component } from "react";
import { Button, Form, FormGroup, Label, Input, Alert } from "reactstrap";
import { withRouter } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";

class LoginForm extends Component {
  state = {
    user: "",
    password: "",
    is_loading: false
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ is_loading: true });
    this.props.onSubmit(this.state, e);
    this.props.history.push("/");
    this.setState({ is_loading: false });
  };

  render() {
    return (
      <div className="cover">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 offset-sm-3">
              <LoadingOverlay
                active={this.state.isLoading}
                spinner
                text="Loading..."
                animate={false}
              >
                <Form onSubmit={this.handleSubmit}>
                  {this.props.message && (
                    <Alert color="danger">{this.props.message}</Alert>
                  )}

                  <h1>{this.props.title}</h1>
                  <FormGroup>
                    <Label for="user">User</Label>
                    <Input
                      type="text"
                      name="user"
                      id="user"
                      placeholder="jdoe"
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="pass">Password</Label>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="password"
                      onChange={this.handleChange}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Button type="submit">Login</Button>
                  </FormGroup>
                </Form>
              </LoadingOverlay>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(LoginForm);
