import axios from "axios";

const store = window.localStorage;

let api = {};

api.set_handleLogout = handleLogout => {
  axios.interceptors.response.use(
    response => {
      return response;
    },
    function(error) {
      if (error.response)
        if (error.response.status === 401) {
          store.removeItem("token");
          // handleLogout();
        }
      return Promise.reject(error.response);
    }
  );
};
export const getAPIURL = () => {
  const url = window._env_.API_URL;
  const domainEnd = url.indexOf("/", 8);
  const pathSegment = url.substring(domainEnd + 1);
  const slashLoc = pathSegment.indexOf("/");

  let cutURL = url;

  if (slashLoc !== -1) {
    cutURL = url.substring(0, domainEnd + slashLoc + 1);
  } else if (domainEnd !== -1) {
    cutURL = url.substring(0, domainEnd + 1);
  }

  const hasTrailingSlash = cutURL.lastIndexOf("/") === cutURL.length - 1;
  if (!hasTrailingSlash) cutURL += "/";

  console.log("api url", cutURL);
  return cutURL;
};


api.base_url = process.env.REACT_APP_API_URL || getAPIURL();
console.log("api url", api.base_url);

const post = (endpoint, data) => {
  const token = store.getItem("token");
  return axios.post(`${api["base_url"]}${endpoint}`, data, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

api.is_logged_in = () =>
  store.getItem("token") !== null &&
  store.getItem("exp") >= new Date().toISOString();
api.logged_in_user = () => store.getItem("user") || "unknown";
api.logged_in_user_role = () => store.getItem("role") || null;
api.logout = () => store.removeItem("token");
api.authenticate = ({ user, password }) => {
  return post("auth", { user: user, password: password }).then(result => {
    store.setItem("token", result.data.token);
    store.setItem("user", user);
    store.setItem("exp", result.data.exp);
    store.setItem("role", result.data.role);
    return [result.data.token !== null, result.data.role, result.data.exp];
  });
};

console.log(store.user);

api.query = (name, params) => {
  return post("query", { name: name, query_params: params });
};

api.report = (name, params) => {
  return post("report", { name: name, query_params: params });
};

api.household_details = (person_id, household_id) => {
  return post("get_household_details", {
    person_id: person_id,
    household_id: household_id
  });
};

api.updatePerson = (person_id, attributes) => {
  return post("update_person", {
    person_id: person_id,
    attributes: attributes
  });
};

api.updateHousehold = (household_id, attributes) => {
  return post("update_household", {
    household_id: household_id,
    attributes: attributes
  });
};

api.updateDisability = (household_id, disability_count) => {
  return post("update_disability", {
    household_id: household_id,
    disability_count: disability_count
  });
};

api.updateIncome = (
  household_id,
  annual_household_income,
  monthly_disability_income,
  monthly_social_security_income,
  monthly_child_support_income,
  monthly_employment_income
) => {
  return post("update_income", {
    household_id: household_id,
    annual_household_income: annual_household_income,
    monthly_disability_income: monthly_disability_income,
    monthly_social_security_income: monthly_social_security_income,
    monthly_child_support_income: monthly_child_support_income,
    monthly_employment_income: monthly_employment_income
  });
};

api.updatePhone = (household_id, home_phone) => {
  return post("update_phone", {
    household_id: household_id,
    home_phone: home_phone
  });
};

api.updateVisit = (visit_id, attributes) => {
  return post("update_visit", {
    visit_id: visit_id,
    attributes: attributes,
    updated_by: store.user
  });
};

api.updateShredded = (household_id, shredded, shredded_date) => {
  return post("update_shredded", {
    household_id: household_id,
    shredded: shredded,
    shredded_date: shredded_date
  });
};

api.checkIn = person_id => {
  return post("check_in", {
    person_id: person_id
  });
};

api.newService = person_id => {
  return post("new_service", {
    person_id: person_id,
    created_by: store.user
  });
};

api.removeService = visit_id => {
  return post("remove_service", {
    visit_id: visit_id
  });
};

api.removeHHMember = person_id => {
  return post("remove_hh_member", {
    person_id: person_id
  });
};

api.newPerson = attributes => {
  return post("new_person", {
    attributes: attributes,
    relationship: "Head of Household"
  });
};

api.newHHMember = household_id => {
  return post("new_hh_member", {
    household_id: household_id
  });
};

api.updateUser = (user, active, role) => {
  return post("update_user", {
    user: user,
    active: active,
    role: role
  });
};

api.bqc_jobs = () => {
  return post("bqc_jobs");
};

api.queue_bqc_job = job_name => {
  return post("queue_bqc_job", { job_name: job_name });
};

api.job_status = () => post("job_status", {});

api.loaded = () => post("loaded");

api.get_person_details = person_id => post("get_person_details", { person_id });

api.updatePassword = (old_password, new_password) => {
  return post("update_auth", {
    old_password: old_password,
    new_password: new_password
  });
};

export default api;
